import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";




export const fetchAlllocality_api = async (data) => {
    let config = {
      method: "post",
      url:getBaseUrl() +"vendor/getlocality",
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      data: data,
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };


  export const getstate_api = async (data) => {
    let config = {
      method: "get",
      url: getBaseUrl() + "vendor/getstate",
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      data: data,
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };

  export const getcity_api = async (data) => {
    let config = {
      method: "post",
      url: getBaseUrl() + "vendor/getcity",
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      data: data,
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };

  export const createlocality_api = async (data) => {
    let config = {
      method: "post",
      url: getBaseUrl() + "admin/addlocality",
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      data: data,
    };
  
    try {
      let res = await axios(config);
      return res;
    } catch (error) {
      console.log(error);
      return error.response;
    }
  };