import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";

const getTestimonials = getBaseUrl() + "admin/getTestimonials";
const status_updateTestimonial = getBaseUrl() + "admin/updateTestimonial";
const deleteTestimonial = getBaseUrl() + "admin/deleteTestimonial";
const addTestimonial = getBaseUrl() + "admin/addTestimonial";
const updateTestimonial = getBaseUrl() + "admin/updateTestimonial";

export const updateTestimonial_api = async (data) => {
  let config = {
    method: "patch",
    url: updateTestimonial,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const addTestimonial_api = async (data) => {
  let config = {
    method: "post",
    url: addTestimonial,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const deleteTestimonial_api = async (data) => {
  let config = {
    method: "delete",
    url: deleteTestimonial,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const status_updateTestimonial_api = async (data) => {
  let config = {
    method: "patch",
    url: status_updateTestimonial,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getbanners_api = async (data) => {
  let config = {
    method: "post",
    url: getBaseUrl()+"admin/threebannerlist",
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const updateBanner_api = async (data) => {
  let config = {
    method: "put",
    url:getBaseUrl()+"admin/threebanner",
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};


// package..................................................................................

export const addPackage_api = async (data) => {
  let config = {
    method: "post",
    url: getBaseUrl()+"admin/createpackage",
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getpackage_api = async (data) => {
  let config = {
    method: "post",
    url: getBaseUrl()+"vendor/packagedetails",
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const updatePackage_api = async (data , id) => {
  let config = {
    method: "put",
    url: getBaseUrl()+`admin/updatepackage/${id}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};


// addon package.............................................................................

export const addPackageaddon_api = async (data) => {
  let config = {
    method: "post",
    url: getBaseUrl()+"admin/createpackageoption",
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getpackageaddon_api = async (data) => {
  let config = {
    method: "post",
    url: getBaseUrl()+"admin/listingOption",
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteAddonPackage_api = async (data) => {
  let config = {
    method: "delete",
    url: getBaseUrl() + "admin/deleteOption",
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const updateAddonPackage_api = async (data) => {
  let config = {
    method: "post",
    url: getBaseUrl() + "admin/updateOption",
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deletePackage_api = async (data) => {
  let config = {
    method: "delete",
    url: getBaseUrl() + "admin/deletepackage",
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};



