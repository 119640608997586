import React, { useEffect, useState } from "react";
import s from "./Dialogbox.module.css";
import { Dialog, DialogActions, DialogTitle, DialogContent, Pagination, Grid } from "@mui/material";
import { getCategories_api } from "../api/category";
import { notificationHandler } from "../../utils/Notification";
import { getcity_api, getstate_api } from "../api/country";

const AssignLeads = ({ open, onclose }) => {
  const [categoryId, setcategoryId] = useState("");
  const [stateId, setstateId] = useState("");
  const [cityId, setcityId] = useState("");
  const [CategoriesArry, setCategoriesArry] = useState([]);
  const [getstate, setgetstate] = useState([]);
  const [getcity, setgetcity] = useState([]);
  useEffect(() => {
    getCategories_function();
    getstate_function();
  }, []);
  const getCategories_function = async () => {
    try {
      let res = await getCategories_api();
      if (!res.data.error) {
        setCategoriesArry(res?.data?.data?.data);
      } else {
        notificationHandler({ type: "success", msg: res.data.message });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };
  const getstate_function = async () => {
    try {
      let res = await getstate_api();
      if (!res.data.error) {
        setgetstate(res.data.data);
      } else {
        notificationHandler({ type: "success", msg: res.data.message });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };
  const getcity_function = async (id) => {
    const temp = {
      stateId: id,
    };
    try {
      let res = await getcity_api(temp);
      if (!res.data.error) {
        setgetcity(res.data.data);
      } else {
        notificationHandler({ type: "success", msg: res.data.message });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };

  return (
    <div>
      <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth="true" onClose={() => onclose()}>
        <DialogTitle className={s.dialog_title}>
          <div>Assign Leads</div>
        </DialogTitle>
        <DialogContent className={s.cardpopup_content}>
          <div style={{ display: "flex", gap: "1rem" }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={3}>
                <div className="form-group">
                  <label for="exampleInputEmail1">Select Categories</label>
                  <div className="  mr-2">
                    <div className="form-group">
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        value={categoryId}
                        onChange={(e) => setcategoryId(e.target.value)}
                        name="role"
                      >
                        <option value="1">Select Category</option>
                        {CategoriesArry.map((data, index) => (
                          <option value={data._id}>{data.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="form-group">
                  <label for="exampleInputEmail1">State</label>
                  <div className="  mr-2">
                    <div className="form-group">
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        value={stateId}
                        onChange={(e) => {
                          setstateId(e.target.value);
                          setcityId("");
                          getcity_function(e.target.value);
                        }}
                        name="role"
                      >
                        <option value="1">Select State</option>
                        {getstate.map((data, index) => (
                          <option value={data._id}>{data.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="form-group">
                  <label for="exampleInputEmail1">city</label>
                  <div className="  mr-2">
                    <div className="form-group">
                      <select className="form-control" id="exampleFormControlSelect1" value={cityId} onChange={(e) => setcityId(e.target.value)} name="role">
                        <option value="1">Select City</option>
                        {getcity.map((data, index) => (
                          <option value={data._id}>{data.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="form-group" style={{ marginTop: "1.5rem" }}>
                  <label for="exampleInputEmail1"></label>
                  <button type="button" class="btn btn-primary">
                    Search
                  </button>
                </div>
              </Grid>
            </Grid>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">First</th>
                <th scope="col">Last</th>
                <th scope="col">Handle</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>Larry</td>
                <td>the Bird</td>
                <td>@twitter</td>
              </tr>
            </tbody>
          </table>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};

export default AssignLeads;
