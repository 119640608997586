import React, { useState } from 'react'
import Modal from "react-bootstrap/Modal";
import logo from "../assets/icons/logo.png";
import s from '../components/Vendor/role.module.css'
import { Form } from 'react-bootstrap';
import { RxCross2 } from 'react-icons/rx';
import OtpInput from "react-otp-input";
import { verify_freelisting_otp } from '../components/api/vendor';
import { notificationHandler } from '../utils/Notification';
import { purchase_package_coupon } from '../components/api/coupon';

const OtpModal = ({otpModalShow,setotpModalShow , mobileNo,type,paymentDetails,paymentType,state}) => {

    const [code, setCode] = useState("");

    const handleChangeOtp = (code) => setCode(code);

      const handleContinue = async() => {
        const data = {
          otp: code,
          phone:mobileNo
        };

        try {
          let res = await verify_freelisting_otp(data);
          if (!res.data.error) {
            notificationHandler({ type: "success", msg: `Congratulation ${type==="paidlisting"?"paid listing":"free listing"} successfully` });
            setotpModalShow(false)
            if(type==="paidlisting"){
              handleDealClose()
            }
          } else {
            notificationHandler({ type: "success", msg: res.data.errormessage });
          }
        } catch (error) {
          notificationHandler({ type: "danger", msg: error.message });
          console.log(error);
        }
      }

      const handleDealClose = async () => {
        let data = {
          type: paymentType === "check" ? "cheque" : "cash",
          id: paymentDetails?._id,
          orderId: paymentDetails?.orderId,
        };
      
        if (paymentType === "check") {
          data.paymentDetails = state;
        }
      
        let res = await purchase_package_coupon(data);
      
        if (!res.data.error) {
          try {
            notificationHandler({ type: "success", msg: "Freelisting successfully" });
          } catch (error) {
            console.log(error);
          }
        }else{
          notificationHandler({ type: "warning", msg: "Freelisting not successfully" });
        }
      };

  return (
    <div>
    <Modal
    show={otpModalShow}
    onHide={() => setotpModalShow(false)}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    className=""
  >
    <Modal.Body>
      <div closeButton >
        <div
          style={{ position: "absolute", right: "3%", fontSize: "22px" }}
          onClick={() => setotpModalShow(false)}
        >
          {" "}
          <RxCross2 />
        </div>
        {/* <Modal.Title id="contained-modal-title-vcenter" className="p-4">
          <div className="text-center">
            <img src={logo} />
          </div>
        </Modal.Title> */}
      </div>
      <Form className="p-4 ">
        <h6 className="text-center">Please enter the code that sent to your mobile no.</h6>
        <div className="row justify-content-center">
          <div className="col-md-8 ">
            {/* <p className="text-center">Enter the code sent to </p> */}
            <OtpInput
              value={code}
              onChange={handleChangeOtp}
              numInputs={4}
              separator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
              isInputNum={true}
              shouldAutoFocus={true}
              inputStyle={{
                border: "1px solid #000",
                borderRadius: "8px",
                width: "54px",
                height: "54px",
                fontSize: "16px",
                color: "#000",
                fontWeight: "400",
                caretColor: "blue",
                margin: "auto",
              }}
              focusStyle={{
                border: "1px solid #CFD3DB",
                outline: "none",
              }}
            />
          </div>
        </div>
      </Form>
    </Modal.Body>

    <div className="text-center mb-5">
      <button className={s["title"]}onClick={handleContinue}>
        Continue
      </button>
    </div>
  </Modal></div>
  )
}

export default OtpModal