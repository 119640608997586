import React, { useEffect, useState } from "react";
import "./Package.css";
import { useLocation, useNavigate } from "react-router-dom";
import { getpackageaddon_api } from "../../api/testimonial";

const AddonPackageShows = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [addCart, setaddCart] = useState([]);

  const [packageaddon, setPackageaddon] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [search, setsearch] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [pageLength, setpageLength] = useState();
  const [packagePrice , setPackagePrice] = useState(location?.state?.data?.packageAmount)
  const [selectAddon , setSelectAddon] = useState(false)
  const [allSelectedAddon , setAllselectedAddon] = useState([])

  useEffect(() => {
    fetchallpackageFunc();
  }, [pageCount, search]);

  async function fetchallpackageFunc(data) {
    // setisLoading(true);
    try {
      const temp = {
        page: pageCount,
        limit: 8,
        search: search.trim(),
      };
      let res = await getpackageaddon_api(temp);
      if (res.data) {
        setpageLength(res?.data?.data?.totalPages);
        setPackageaddon(res?.data?.data);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleCart = (id, elem) => {
    if (addCart.includes(elem.name)) {
      const updatefilter = addCart.filter((item, ind) => item !== elem.name);
      const updateselectedAllfilter = allSelectedAddon.filter((item, ind) => item.name !== elem.name);
      setaddCart(updatefilter);
      setPackagePrice((...prev)=> prev-elem.amount)
      setAllselectedAddon(updateselectedAllfilter)
    } else {
      setaddCart((prev) => [...prev, elem.name]);
      let addTotalPrice = packagePrice + (+elem.amount)
      setPackagePrice(addTotalPrice)
      setAllselectedAddon((prev) => [...prev , elem])
    }
  };


  useEffect(() => {
    // Assuming addCart is the state you're checking
    if (addCart.length > 0 && !selectAddon) {
      setSelectAddon(true);
    } else if (addCart.length === 0 && selectAddon) {
      setSelectAddon(false);
    }
  }, [addCart, selectAddon]);
  
  const handleContinue=()=>{
    navigate("/select-area" , {state:{addonPackage:allSelectedAddon , package:location?.state?.data , totalPackagePrice:packagePrice}})
  }
  const handleSkip=()=>{
    navigate("/select-area",{state:{ package:location?.state?.data , totalPackagePrice:packagePrice}})
  }

  return (
    <div>
      <div className="" style={{ marginTop: "3rem" }}>
        <div
          className="user-list-title"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h3>Addon Package List</h3>
          <button className="addedCart">Selected Package Price : {packagePrice}</button>
          {selectAddon ?<button className="addedCart" onClick={handleContinue}>Continue</button>:<button className="addedCart" onClick={handleSkip}>Skip</button>}
        </div>
        <div className="packList">
          {packageaddon?.length > 0
            ? packageaddon.map((elem, id) => {
                return (
                  <div className="packContainer">
                    <input
                      type="radio"
                      id="personal"
                      className="packRadio nonactivepackRadio"
                      name="pack"
                    />
                    <label className="nonactivePack pack" htmlFor="personal">
                      <div className="header">
                        <h2>{elem?.name}</h2>
                      </div>
                      <div className="price" style={{ marginTop: "0.5rem" }}>
                        <img src={elem.image} width={"50px"} />
                      </div>
                      <div className="price" style={{ marginTop: "0.5rem" }}>
                        <p>
                          Starting at
                          <b>{elem?.amount} INR</b>
                        </p>
                      </div>

                      <div className="features">
                        <p>{elem?.description}</p>
                        <button
                          className={
                            addCart.includes(elem.name)
                              ? "addedCart"
                              : "cartButton"
                          }
                          onClick={() => handleCart(id, elem)}
                        >
                          Add in Cart
                        </button>
                      </div>
                    </label>
                  </div>
                );
              })
            : ""}
          {/* <div className="packContainer">
        <input
          type="radio"
          id="institutional"
          className="packRadio"
          name="pack"
          defaultChecked="checked"
        />
        <label className="pack" htmlFor="institutional">
          <div className="header">
            <h2>Institutional</h2>
          </div>
          <div className="price">
            <p>
              Starting at
              <b>50$</b>
            </p>
          </div>
          <div className="features">
            <p>
              Perfect for organizing personal events such as weddings, baby
              showers, baptems, birthdays.
            </p>
           
          </div>
        </label>
      </div>
      <div className="packContainer">
        <input type="radio" id="social" className="packRadio" name="pack" />
        <label className="pack" htmlFor="social">
          <div className="header">
            <h2>Social &amp; Corporate</h2>
          </div>
          <div className="price">
            <p>
              Starting at
              <b>100$</b>
            </p>
          </div>
          <div className="features">
            <p>
              Perfect for organizing personal events such as weddings, baby
              showers, baptems, birthdays.
            </p>
            
          </div>
        </label>
      </div> */}
        </div>
      </div>
    </div>
  );
};

export default AddonPackageShows;
