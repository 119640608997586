import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";

const getCategories = getBaseUrl();
const addCategory = getBaseUrl() + "service/createcategory";
const updateCategory = getBaseUrl() + "service/updatecategory";

export const updateCategory_api = async (data) => {
  let config = {
    method: "post",
    url: updateCategory,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const addCategory_api = async (data) => {
  let config = {
    method: "post",
    url: addCategory,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const status_update_categories_api = async (data) => {
  let config = {
    method: "post",
    url: updateCategory,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getCategories_api = async (data) => {
  let config = {
    method: "post",
    // url: getCategories + `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
    url: getCategories + `service/getallcategories`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
