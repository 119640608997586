import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";

const getstate = getBaseUrl() + "vendor/getstate";
const getcity = getBaseUrl() + "vendor/getcity";

export const getcity_api = async (data) => {
  let config = {
    method: "post",
    url: getcity,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getstate_api = async (data) => {
  let config = {
    method: "get",
    url: getstate,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
