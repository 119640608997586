import React, { useState } from "react";
import { RxDashboard } from "react-icons/rx";
import s from "./sidebar.module.css";
import { FiUsers } from "react-icons/fi";
import { MdOutlineArticle, MdEvent } from "react-icons/md";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import logo from "../../assets/icons/logo.png";
import { useNavigate } from "react-router-dom";
const Sidebar = ({ open }) => {
  const navigate = useNavigate();
  const [menuItems, setmenuItems] = useState([
    {
      title: " Dashboard",
      icons: <RxDashboard size={20} />,
      navigate: "dashboard",
      show: false,
      subItems: [],
    },
    {
      title: "Add Locality",
      icons: <MdOutlineAdminPanelSettings size={20} />,
      navigate: "locality-list",
      show: false,
      subItems: [],
    },
    // {
    //   title: " Role",
    //   icons: <MdOutlineArticle size={20} />,
    //   navigate: "role",
    //   show: false,
    //   subItems: [],
    // },
    {
      title: " Users",
      icons: <FiUsers size={20} />,
      navigate: "users",
      show: false,
      subItems: [],
    },
    {
      title: "Services",
      icons: <MdEvent size={20} />,
      navigate: "service-list",
      show: false,
      subItems: [],
    },
    {
      title: "Category",
      icons: <MdEvent size={20} />,
      navigate: "category-list",
      show: false,
      subItems: [],
    },

    {
      title: "Sub Categories",
      icons: <MdEvent size={20} />,
      navigate: "sub-categories-list",
      show: false,
      subItems: [],
    },
    {
      title: "Add Leads",
      icons: <MdEvent size={20} />,
      navigate: "leads",
      show: false,
      subItems: [],
    },
    {
      title: "Vendor",
      icons: <MdEvent size={20} />,
      navigate: "vendor-list",
      show: false,
      subItems: [],
    },
    {
      title: "Banner",
      icons: <MdEvent size={20} />,
      navigate: "banner-list",
      show: false,
      subItems: [],
    },
    {
      title: "Package",
      icons: <MdEvent size={20} />,
      navigate: "package-list",
      show: false,
      subItems: [],
    },
    {
      title: "Add On Package",
      icons: <MdEvent size={20} />,
      navigate: "addonpackage-list",
      show: false,
      subItems: [],
    },
  ]);
  return (
    <>
      <section className={s["sidebar"]}>
        <div className={`${s["collapsed-logo"]} ${open ? `${s["logo-section"]}` : `${s["logo-section-hide"]}`}`} style={{ width: "80%" }}>
          <img src={logo} alt="logo" draggable="false" />
        </div>
        {menuItems.map((data, i) => (
          <div className={s["sidebar-content"]}>
            <div className={s["sidebar-item"]} onClick={() => navigate(`/${data?.navigate}`)}>
              <div className="sidebaricons">{data.icons}</div>
              <div className={open ? `${s["sidebar-title"]}` : `${s["sidebar-title-hide"]}`}>{data.title}</div>
            </div>
          </div>
        ))}
      </section>
    </>
  );
};

export default Sidebar;
