import React, { useState, useEffect } from "react";
import s from "./category.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { addCategory_api, updateCategory_api } from "../api/category";
import { fetchAllservice_api } from "../api/service";
const AddCategory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setname] = useState("");
  const [file, setfile] = useState(null);
  const [services, setservices] = useState([]);
  const [servicesId, setservicesId] = useState("");
  const pagetype = location.state.pagetype;
  const id = location?.state?.data?._id;
  useEffect(() => {
    setname(location?.state?.data?.name);
    setservicesId(location?.state?.data?.serviceId);
  }, [location]);

  const Category_function = async () => {
    if (pagetype == "Add") {
      const fd = new FormData();
      fd.append("name", name);
      fd.append("Image", file);
      fd.append("serviceId", servicesId);

      try {
        let res = await addCategory_api(fd);
        if (!res.data.error) {
          navigate("/category-list");
          notificationHandler({ type: "success", msg: res.data.data });
        } else {
          notificationHandler({ type: "success", msg: res.data.data });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.data });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      const fd = new FormData();
      fd.append("name", name);
      fd.append("Image", file);
      fd.append("id", id);
      fd.append("serviceId", servicesId);
      try {
        let res = await updateCategory_api(fd);
        if (!res.data.error) {
          navigate("/category-list");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getAllservices();
  }, []);
  const getAllservices = async () => {
    try {
      let res = await fetchAllservice_api();
      if (!res.data.error) {
        setservices(res.data.data.data);
      } else {
        notificationHandler({ type: "success", msg: res.data.message });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };

  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Category Name</label>
                <input type="text" className="form-control" name="name" value={name} onChange={(e) => setname(e.target.value)} placeholder="Category Name" />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Select Image</label>
                <div className="mr-2">
                  <input type="file" className="form-control" name="img" placeholder="" accept="image/*" onChange={(e) => setfile(e.target.files[0])} />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Select Service</label>
                <div className="  mr-2">
                  <div className="form-group">
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      value={servicesId}
                      onChange={(e) => setservicesId(e.target.value)}
                      name="role"
                    >
                      <option value="1">Select Service</option>
                      {services.map((data, index) => (
                        <option value={data._id}>{data.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>

          <div className={s["form-login-btn"]} onClick={() => Category_function()}>
            <Custombutton>{pagetype == "Add" ? "Submit" : "Update"} </Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddCategory;
