import React from "react";
import Admin from "./pages/Admin";
import Adminadd from "./pages/Adminadd";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/profile";
import UserAdd from "./pages/UserAdd";
import Users from "./pages/Users";
import { ServicesList } from "./pages/ServicesList";
import AddServices from "./components/Services/AddServices";
import Category from "./pages/Category";
import AddCategoryPage from "./pages/AddCategoryPage";
import SubCategories from "./pages/SubCategories";
import AddSubCategories from "./pages/AddSubCategories";
import Banner from "./pages/Banner";
import AddTestimonial from "./pages/AddTestimonial";
import VendorProfile from "./components/Vendor/VendorProfile";
import LeadsList from "./components/Leads/LeadsList";
import AddLeads from "./components/Leads/AddLeads";
import PackageList from "./components/Package/PackageList";
import PackageAdd from "./components/Package/PackageAdd";
import AddLocality from "./components/AddLocality/AddLocality";
import LocalityList from "./components/AddLocality/LocalityList";
import AddonPackagelist from "./components/AddonPackage/AddonPackagelist";
import AddonPackage from "./components/AddonPackage/AddonPackage";
import SelectPackage from "./components/Vendor/PaidService/SelectPackage";
import AddonPackageShows from './components/Vendor/PaidService/AddonPackageShows';
import SelectArea from "./components/Vendor/PaidService/SelectArea";
import AddBanner from "./pages/AddTestimonial";
import AddVendor from "./components/Vendor/AddVendor";
import VendorList from "./components/Vendor/VendorList";

 
const routeArray = [
  // { params: undefined, component: <Home1 /> },
  { params: "admin", component: <Admin /> },
  { params: "users", component: <Users /> },
  
  { params: "dashboard", component: <Dashboard /> },
  { params: "adminadd", component: <Adminadd /> },
  { params: "useradd", component: <UserAdd /> },

  { params: "add-vendor", component: <AddVendor /> },
  { params: "vendor-list", component: <VendorList /> },

  { params: "profile", component: <Profile /> },
  { params: "service-list", component: <ServicesList /> },
  { params: "add-services", component: <AddServices /> },
  { params: "category-list", component: <Category /> },
  { params: "add-category", component: <AddCategoryPage /> },
  { params: "sub-categories-list", component: <SubCategories /> },
  { params: "add-sub-categories", component: <AddSubCategories /> },

  { params: "banner-list", component: <Banner /> },
  { params: "banner-add", component: <AddBanner /> },

  { params: "package-list", component: <PackageList /> },
  { params: "package-add", component: <PackageAdd /> },
  // { params: "testimonial-add", component: <AddTestimonial /> },
  { params: "vendor-profile", component: <VendorProfile /> },
  { params: "leads", component: <LeadsList /> },
  { params: "addleads", component: <AddLeads /> },

  { params: "locality-list", component: <LocalityList /> },
  { params: "add-locality", component: <AddLocality /> },

  { params: "addonpackage-list", component: <AddonPackagelist /> },
  { params: "addonpackage-add", component: <AddonPackage /> },

  { params: "select-package", component: <SelectPackage/> },
  { params: "select-area", component: <SelectArea/> },
  { params: "addonpackage-shows", component: <AddonPackageShows/> },
];

export default routeArray;
