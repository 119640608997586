import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";

const user_delete = getBaseUrl() + "admin/deleteUser";
const user_status = getBaseUrl() + "admin/updateUser";
const user_update = getBaseUrl() + "admin/updateUser";
const users_list = getBaseUrl() + "admin/allusers";

export const user_update_api = async (data) => {
  let config = {
    method: "patch",
    url: user_update,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const user_status_api = async (data) => {
  let config = {
    method: "patch",
    url: user_status,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const user_delete_api = async (data) => {
  let config = {
    method: "DELETE",
    url: user_delete,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const fetchAllUsers = async (data) => {
  let config = {
    method: "post",
    url: users_list,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
