import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";

const adduserdetails = getBaseUrl() + "vendor/updatebusinessdetails";
const updatecontactdetails = getBaseUrl() + "vendor/updatecontactdetails";
const updateadderess = getBaseUrl() + "vendor/updateadderess";
const findallbusiness = getBaseUrl() + "admin/findallbusiness";
const timeslot = getBaseUrl() + "vendor/updatevenderdetails";
const updateapaymenttype = getBaseUrl() + "vendor/updateapaymenttype";
const uploadsocialmedia = getBaseUrl() + "vendor/uploadsocialmedia";
const findvendorbyid = getBaseUrl() + "vendor/findvendorbyid";
const uploadgallary = getBaseUrl() + "vendor/uploadgallary";
const uploadbanner = getBaseUrl() + "vendor/banner";
const uploaddocuments = getBaseUrl() + "vendor/uploaddocuments";
const vendorbackgroundimage = getBaseUrl() + "vendor/vendorbackgroundimage";
const vendorprofileimage = getBaseUrl() + "vendor/vendorprofileimage";
const getstate = getBaseUrl() + "vendor/getstate";
const getcity = getBaseUrl() + "vendor/getcity";

export const getcity_api = async (data) => {
  let config = {
    method: "post",
    url: getcity,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const getstate_api = async (data) => {
  let config = {
    method: "get",
    url: getstate,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const vendorprofileimage_api = async (data) => {
  let config = {
    method: "post",
    url: vendorprofileimage,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const vendorbackgroundimage_api = async (data) => {
  let config = {
    method: "post",
    url: vendorbackgroundimage,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const uploaddocuments_api = async (data) => {
  let config = {
    method: "post",
    url: uploaddocuments,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const uploadgallary_api = async (data) => {
  let config = {
    method: "post",
    url: uploadgallary,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const uploadbanner_api = async (data) => {
  let config = {
    method: "post",
    url: uploadbanner,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const findvendorbyid_api = async (data) => {
  let config = {
    method: "post",
    url: findvendorbyid,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const updatetimeslot_vendord_api = async (data) => {
  let config = {
    method: "post",
    url: timeslot,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const findallbusiness_api = async (data) => {
  let config = {
    method: "post",
    url: findallbusiness,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const updateadderess_vendord_api = async (data) => {
  let config = {
    method: "post",
    url: updateadderess,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const updatecontactdetails_api = async (data) => {
  let config = {
    method: "post",
    url: updatecontactdetails,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const adduserdetails_api = async (data) => {
  let config = {
    method: "post",
    url: adduserdetails,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const updateapaymenttype_api = async (data) => {
  let config = {
    method: "post",
    url: updateapaymenttype,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const uploadsocialmedia_api = async (data) => {
  let config = {
    method: "post",
    url: uploadsocialmedia,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const sendotp_api = async (data) => {
  let config = {
    method: "post",
    url: getBaseUrl()+"admin/freelistingotpsend",
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};


export const verify_freelisting_otp = async (data) => {
  let config = {
    method: "post",
    url: getBaseUrl()+"admin/freelistingotpverify",
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const delete_vendor_api = async (data) => {
  let config = {
    method: "delete",
    url:  getBaseUrl()+"vendor/deleteVendor",
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};