import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./user.module.css";
import { useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";
import { fetchAllUsers } from "../api/user";
import Loder from "../../Loder/Loder";
import DataNotFound from "../ErrorPage/DataNotFound";
import { BiSearch } from "react-icons/bi";
import xlsx from "json-as-xlsx";
import { notificationHandler } from "../../utils/Notification";

const MainPage = () => {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [allUsers, setallUsers] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [pageLength, setpageLength] = useState();
  const [search, setsearch] = useState("");
  useEffect(() => {
    fetchAllUsersFunc();
  }, [pageCount, search]);

  async function fetchAllUsersFunc(data) {
    setisLoading(true);
    try {
      const temp = {
        page: pageCount,
        limit: 8,
        search: search.trim(),
      };
      let res = await fetchAllUsers(temp);
      if (!res.data.error) {
        setallUsers(res.data.data.result);
        setpageLength(res?.data?.data?.totalPages);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }
  let settings = {
    fileName: "HSM Users", 
    extraLength: 3,
    writeMode: "writeFile", 
    writeOptions: {}, 
    RTL: false, 
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--clr-theme)",
      color: theme.palette.common.white,
      fontWeight: "bold",
      borderRight: "1px solid #fff",
      overflow: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      borderLeft: "2px solid #00000011",
      "&:last-child": {
        borderRight: "2px solid #00000011",
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "2px solid #00000011",
  }));

  const exportFunc = () => {
    let data = [
      {
        sheet: "Users",
        columns: [
          { label: "ID", value: (row) => (row.id ? row._id : "") },
          { label: "Name", value: (row) => (row.name ? row.name : "") },
          { label: "Email", value: (row) => (row.email ? row.email : "") },
          { label: "Phone", value: (row) => (row.mobile_number ? row.mobile_number : "") },
        ],
        content: allUsers,
      },
    ]
    try {
      xlsx(data, settings, callback);
    } catch (error) {
      console.log(error);
    }
  };

  let callback = function (sheet) {
    console.log("Download complete:", sheet);
  };

  return (
    <div className="">
      <div className="beat_heading">
        <div className={s["user-list-heading"]}>
          <div className="user-list-title">
            <h3>Users List</h3>
          </div>
          <div className={s["user-list-search"]}>
            <div className={s["search-box"]}>
              <span style={{ paddingRight: "0.5rem" }}>
                <BiSearch size={23} />
              </span>
              <input
                type="text"
                spellCheck="false"
                onChange={(e) => {
                  setpageCount(1);
                  setsearch(e.target.value);
                }}
                placeholder="Search users by name.."
              />
            </div>
          </div>
        </div>
        <div className={s["user-list-heading"]}>
        <div className={s["user-list-title"]}>
          <div className="beat_left">
            <div
              className={s["title"]}
              onClick={()=>exportFunc()}
            >
              Export User List
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className="beat_table">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Image</StyledTableCell>
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">Email</StyledTableCell>
              <StyledTableCell align="center">Number</StyledTableCell>
              <StyledTableCell align="center">Created</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {allUsers.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell>{row.profile_image ? <img style={{ height: "2rem", width: "3rem" }} src={row.profile_image} alt="" /> : null}</StyledTableCell>

                <StyledTableCell>{row.name} </StyledTableCell>
                <StyledTableCell align="center">{row.email} </StyledTableCell>
                <StyledTableCell align="center">{row.mobile_number}</StyledTableCell>
                <StyledTableCell align="center">{row.updatedAt}</StyledTableCell>
             
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {allUsers.length <= 0 && <DataNotFound />}
        {allUsers?.length > 0 && (
          <div className={s["pagination"]}>
            <Pagination count={pageLength} size="large" style={{ color: "#D21903" }} onChange={(e, value) => setpageCount(value)} page={pageCount} />
          </div>
        )}
      </div>

      <Loder loading={isLoading} />
    </div>
  );
};

export default MainPage;
