import React, { useState, useEffect } from "react";
import s from "./role.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { craetevenderprofile_api, createRole_api, role_update_api } from "../api/role";
import { getCategories_api } from "../api/category";
import { fetchAlllocality_api, getcity_api, getstate_api } from "../api/locality";

const AddVendor = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setname] = useState("");
  const [categoryId, setcategoryId] = useState("");
  const [allcategories, setallcategories] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [pinCode, setpinCode] = useState("");
  const [areaName , setAreaName] = useState('')
  const [constactPersonName, setconstactPersonName] = useState("");
  const [companyName, setcompanyName] = useState("");
  const [mobileNumber, setmobileNumber] = useState("");

  const [state, setstate] = useState([]);
  const [stateName , setStateName] = useState([])
  const [cityName , setcityName] = useState([])
  const [city , setCity] = useState([])
  const [area, setarea] = useState([]);

  const pagetype = location.state.pagetype;
  useEffect(() => {
    setname(location?.state?.data?.role_name);
  }, [location]);

  const role_function = async () => {
    if (pagetype == "Add") {
      let temp = {
        mobileNumber: mobileNumber,
        companyName: companyName,
        constactPersonName: constactPersonName,
        area: areaName,
        pinCode: pinCode,
        categoryId: categoryId,
      };
      try {
        let res = await craetevenderprofile_api(temp);
        if (!res.data.error) {
          navigate("/vendor-list");
          notificationHandler({ type: "success", msg: "Create vendor Successfully" });
        } else {
          notificationHandler({ type: "warning", msg: "Vendor create not Successfully" });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      let temp = {
        name: name,
        id: location?.state?.data?.id,
      };
      try {
        let res = await role_update_api(temp);
        if (res.data.status) {
          navigate("/vendor-list");
          notificationHandler({ type: "success", msg: "Update Successfully" });
        } else {
          notificationHandler({ type: "warning", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchallcategoriesFunc();
  }, []);

  async function fetchallcategoriesFunc(data) {
    setisLoading(true);
    try {
      let res = await getCategories_api();
      if (!res.data.error) {
        setallcategories(res?.data?.data?.data);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getstate_function = async () => {
   
    try {
      let res = await getstate_api();
      if (!res.data.error) {
        setStateName(res.data.data);
      } else {
        notificationHandler({ type: "success", msg: res.data.message });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };

  const getcity_function = async (id) => {
    const temp = {
      stateId: id,
    };
    try {
      let res = await getcity_api(temp);
      if (!res.data.error) {
        setcityName(res.data.data);
      } else {
        notificationHandler({ type: "success", msg: res.data.message });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };

  useEffect(()=>{
    getstate_function()
  },[])

  async function fetchalllocalityFunc() {
    setisLoading(true);
    try {
      const temp = {
       
        cityId:city
      };
      let res = await fetchAlllocality_api(temp);
      if (!res.data.error) {
        console.log(res);
        setarea(res?.data?.data);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(()=>{
    fetchalllocalityFunc()
  },[city])

  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <section className={s["profile-section"]}>
            {" "}
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={6}>
                <div className="form-group">
                  <label for="exampleInputEmail1">Company Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={companyName}
                    onChange={(e) => setcompanyName(e.target.value)}
                    placeholder="Company Name"
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="form-group">
                  <label for="exampleInputEmail1">Owner Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={constactPersonName}
                    onChange={(e) => setconstactPersonName(e.target.value)}
                    placeholder="Owner Name"
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
            <div className="form-group">
                  <label for="exampleInputEmail1">State</label>
                  <div className="  mr-2">
                    <div className="form-group">
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        value={state}
                        onChange={(e) => {
                          setstate(e.target.value);
                          setCity("");
                          getcity_function(e.target.value);
                        }}
                        name="role"
                      >
                        <option value="1">Select City</option>
                        {stateName?.map((data, index) => (
                          <option value={data._id}>{data.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
            </Grid>

            <Grid item xs={6}>
            <div className="form-group">
                  <label for="exampleInputEmail1">City</label>
                  <div className="  mr-2">
                    <div className="form-group">
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        value={city}
                        onChange={(e) => {
                          setCity(e.target.value);
                        }}
                        name="role"
                      >
                        <option value="1">Select State</option>
                        {cityName.map((data, index) => (
                          <option value={data._id}>{data.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
            </Grid>
              {/* <Grid item xs={6}>
                <div className="form-group">
                  <label for="exampleInputEmail1">Area</label>
                  <input type="text" className="form-control" name="area" value={area} onChange={(e) => setarea(e.target.value)} placeholder="Enter Area" />
                </div>
              </Grid> */}
               <Grid item xs={6}>
            <div className="form-group">
                  <label for="exampleInputEmail1">Area</label>
                  <div className="  mr-2">
                    <div className="form-group">
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        value={areaName}
                        onChange={(e) => {
                          setAreaName(e.target.value);
                        }}
                        name="role"
                      >
                        <option value="1">Select Area</option>
                        {area.map((data, index) => (
                          <option value={data.name}>{data.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
            </Grid>
              <Grid item xs={6}>
                <div className="form-group">
                  <label for="exampleInputEmail1">Mobile Number</label>
                  <input
                    type="text"
                    className="form-control"
                    name="mobileNumber"
                    value={mobileNumber}
                    onChange={(e) => setmobileNumber(e.target.value)}
                    placeholder="Mobile Number"
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="form-group">
                  <label for="exampleInputEmail1">PinCode</label>
                  <input
                    type="text"
                    className="form-control"
                    name="pinCode"
                    value={pinCode}
                    onChange={(e) => setpinCode(e.target.value)}
                    placeholder="Enter pinCode"
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="form-group">
                  <label for="exampleInputEmail1">Select Category</label>
                  <div className="  mr-2">
                    <div className="form-group">
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        value={categoryId}
                        onChange={(e) => setcategoryId(e.target.value)}
                        name="role"
                      >
                        <option value="1">Select Service</option>
                        {allcategories.map((data, index) => (
                          <option value={data._id}>{data.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </section>
          
          <div className={s["form-login-btn"]} onClick={() => role_function()}>
            <Custombutton>Create</Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddVendor;
