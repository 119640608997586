import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";
const createservice = getBaseUrl() + "service/createservice";
const getallservice = getBaseUrl() + "service/getallservice";
const updateservice = getBaseUrl() + "service/updateservice";
const updatestatusservice = getBaseUrl() + "service/updateservice";

export const update_service_api = async (data) => {
  let config = {
    method: "post",
    url: updateservice,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const createservice_api = async (data) => {
  let config = {
    method: "post",
    url: createservice,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const status_update_service_api = async (data) => {
  let config = {
    method: "post",
    url: updatestatusservice,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const fetchAllservice_api = async (data) => {
  let config = {
    method: "post",
    url: getallservice,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
