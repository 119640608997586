import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "../../Services/services.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Pagination } from "@mui/material";
import Loder from "../../../Loder/Loder";
import DataNotFound from "../../ErrorPage/DataNotFound";
import { BiFilter, BiSearch } from "react-icons/bi";
import { status_update_service_api } from "../../api/service";
import { notificationHandler } from "../../../utils/Notification";
import { fetchAlllocality_api } from "../../api/locality";
import { getcity_api, getstate_api } from "../../api/vendor";
import PaymentPopUp from "./PaymentPopUp";
import PaymentTypePopUp from "./PaymentTypePopUp";

const SelectArea = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setisLoading] = useState(false);
  const [alllocality, setalllocality] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [pageLength, setpageLength] = useState();
  const [search, setsearch] = useState("");
  const [state, setstate] = useState([]);
  const [stateName, setStateName] = useState([]);
  const [cityName, setcityName] = useState([]);
  const [city, setCity] = useState([]);
  const [selectedCustomerArr, setselectedCustomerArr] = useState([]);
  const [openPaymentModal, setOpenpaymentModal] = useState(false);
 const [paymentType , setPaymentType] = useState('')
 const [paymentTypeModal , setPaymentTypeModal] = useState(false);
 const [paymentDetails , setPaymentDetails] = useState();

  async function fetchalllocalityFunc() {
    setisLoading(true);
    try {
      const temp = {
        page: pageCount,
        limit: 8,
        search: search.trim(),
        cityId: city,
      };
      let res = await fetchAlllocality_api(temp);
      if (!res.data.error) {
        console.log(res);
        setpageLength(res?.data?.data?.totalPages);
        setalllocality(res?.data?.data);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--clr-theme)",
      color: theme.palette.common.white,
      fontWeight: "bold",
      borderRight: "1px solid #fff",
      overflow: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      borderLeft: "2px solid #00000011",
      "&:last-child": {
        borderRight: "2px solid #00000011",
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "2px solid #00000011",
  }));

  const getstate_function = async () => {
    try {
      let res = await getstate_api();
      if (!res.data.error) {
        setStateName(res.data.data);
      } else {
        notificationHandler({ type: "success", msg: res.data.message });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };

  const getcity_function = async (id) => {
    const temp = {
      stateId: id,
    };
    try {
      let res = await getcity_api(temp);
      if (!res.data.error) {
        setcityName(res.data.data);
      } else {
        notificationHandler({ type: "success", msg: res.data.message });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };

  useEffect(() => {
    getstate_function();
  }, []);

  const service_status = async (data) => {
    const id = data._id;
    setisLoading(true);

    const fd = new FormData();
    fd.append("status", data.is_active == true ? false : true);
    fd.append("id", id);

    try {
      let res = await status_update_service_api(fd);
      console.log(res);
      if (!res.data.error) {
        setisLoading(false);
        fetchalllocalityFunc();
        notificationHandler({ type: "success", msg: res.data.data });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.data });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.data });
    }
  };

  useEffect(() => {
    fetchalllocalityFunc();
  }, [pageCount, search, cityName, city]);

  const selectionCheckboxFunc = (e, row) => {
    if (!selectedCustomerArr.includes(row._id)) {
      setselectedCustomerArr([...selectedCustomerArr, row._id]);
    } else {
      let filteredArr = selectedCustomerArr.filter(
        (item, id) => item !== row._id
      );
      setselectedCustomerArr(filteredArr);
    }
  };

  return (
    <div className="container">
      <div className={s["article-list-title"]}>
        <h3>Locality List</h3>
      </div>
      <div className={s["user-list-heading"]}>
        <div className={s["user-list-title"]}>
          <div className="beat_left">
            <div
              className={s["title"]}
              onClick={()=>setOpenpaymentModal(true)}
            >
              Proceed to Pay
            </div>
          </div>
        </div>
        <div className={s["user-list-title"]}>
          <div className="beat_left">
            <div
              className={s["title"]}
              onClick={() =>
                navigate("/add-locality", {
                  state: {
                    pagetype: "Add",
                  },
                })
              }
            >
              Total Package Price :{location?.state?.totalPackagePrice}
            </div>
          </div>
        </div>
        
      </div>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <div className="form-group">
            <label for="exampleInputEmail1">State</label>
            <div className="  mr-2">
              <div className="form-group">
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  value={state}
                  onChange={(e) => {
                    setstate(e.target.value);
                    setCity("");
                    getcity_function(e.target.value);
                  }}
                  name="role"
                >
                  <option value="1">Select City</option>
                  {stateName?.map((data, index) => (
                    <option value={data._id}>{data.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={6}>
          <div className="form-group">
            <label for="exampleInputEmail1">City</label>
            <div className="  mr-2">
              <div className="form-group">
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  value={city}
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                  name="role"
                >
                  <option value="1">Select State</option>
                  {cityName.map((data, index) => (
                    <option value={data._id}>{data.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <div className="beat_table" style={{ marginTop: "2rem" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">S. No.</StyledTableCell>
              <StyledTableCell align="center">State Name</StyledTableCell>
              <StyledTableCell align="center">City Name</StyledTableCell>
              <StyledTableCell align="center">Locality Name</StyledTableCell>
              {/* <StyledTableCell align="center">Status</StyledTableCell> */}
              {/* <StyledTableCell align="center">Action</StyledTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {alllocality?.map((row, id) => (
              <StyledTableRow key={row._id}>
                <StyledTableCell>
                  <input
                    onChange={(e) => selectionCheckboxFunc(e, row)}
                    checked={selectedCustomerArr.includes(row._id)}
                    type="checkbox"
                    style={{ marginRight: "0.5rem" }}
                  />
                  {id + 1}
                </StyledTableCell>
                <StyledTableCell>{row.stateName}</StyledTableCell>
                <StyledTableCell>{row.cityName} </StyledTableCell>
                <StyledTableCell>{row.name} </StyledTableCell>
                {/* <StyledTableCell align="center">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => service_status(row)}
                    className={`${
                      row.is_active === true ? s.active_admin : s.inactive_admin
                    }`}
                  >
                    {row.is_active === true ? "Active" : "Inactive"}
                  </div>
                </StyledTableCell> */}
                {/* <StyledTableCell align="center">
                  <CiEdit
                    onClick={() =>
                      navigate("/add-services", {
                        state: {
                          pagetype: "Edit",
                          data: row,
                        },
                      })
                    }
                    style={{
                      fontSize: "1rem",
                      color: "var(--clr-primary)",
                      marginRight: "0.5rem",
                      cursor: "pointer",
                    }}
                  />
                </StyledTableCell> */}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {alllocality?.length <= 0 && <DataNotFound />}
        {alllocality?.length > 0 && (
          <div className={s["pagination"]}>
            <Pagination
              count={pageLength}
              size="large"
              style={{ color: "#D21903" }}
              onChange={(e, value) => setpageCount(value)}
              page={pageCount}
            />
          </div>
        )}
      </div>

      <Loder loading={isLoading} />
      <PaymentPopUp
      openPaymentModal={openPaymentModal}
      setOpenpaymentModal={setOpenpaymentModal}
      paymentTypeModal={paymentTypeModal}
      setPaymentTypeModal={setPaymentTypeModal}
      paymentType={paymentType}
      setPaymentType={setPaymentType}
      packageData={location?.state?.package}
      addonPackage={location?.state?.addonPackage}
      selecetedLocality={selectedCustomerArr}
      setPaymentDetails={setPaymentDetails}
      paymentDetails={paymentDetails}
      />
      <PaymentTypePopUp 
      paymentType={paymentType}
      setPaymentType={setPaymentType}
      paymentTypeModal={paymentTypeModal}
      setPaymentTypeModal={setPaymentTypeModal}
      packageData={location?.state?.package}
      addonPackage={location?.state?.addonPackage}
      selecetedLocality={selectedCustomerArr}
      setPaymentDetails={setPaymentDetails}
      paymentDetails={paymentDetails}
      />
    </div>
  );
};

export default SelectArea;
