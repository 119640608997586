import React from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";



export const create_order_coupon = async (data) => {
  let config = {
    method: "post",
    url: getBaseUrl() + "vendor/createOrder",
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const purchase_package_coupon = async (data) => {
  let config = {
    method: "post",
    url: getBaseUrl() + "vendor/purchasePackage",
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
// export const deleteAstrologer_api = async (data) => {
//   let config = {
//     method: "delete",
//     url: deleteAstrologer,
//     headers: { Authorization: `Bearer ${Cookies.get("token")}` },
//     data: data,
//   };

//   try {
//     let res = await axios(config);
//     return res;
//   } catch (error) {
//     console.log(error);
//     return error.response;
//   }
// };
// export const addAstrologer_api = async (data) => {
//   let config = {
//     method: "post",
//     url: addAstrologer,
//     headers: { Authorization: `Bearer ${Cookies.get("token")}` },
//     data: data,
//   };

//   try {
//     let res = await axios(config);
//     return res;
//   } catch (error) {
//     console.log(error);
//     return error.response;
//   }
// };

// export const getAstrologers_api = async (data) => {
//   let config = {
//     method: "get",
//     url: getAstrologers + `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
//     headers: { Authorization: `Bearer ${Cookies.get("token")}`, "Content-Type": "application/json" },
//     // data: data,
//   };

//   try {
//     let res = await axios(config);
//     return res;
//   } catch (error) {
//     console.log(error);
//     return error.response;
//   }
// };
