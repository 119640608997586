import React, { useState, useEffect } from "react";
import s from "../Banner/testimonial.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
// import { createRole_api, role_update_api } from "../api/role";
import { addPackageaddon_api, updateAddonPackage_api } from "../api/testimonial";
import { toast } from "react-toastify";
const AddonPackage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pagetype = location.state.pagetype;

  const [packageAdd , setPackageAdd] = useState({
    packageName:"",
    packageAmount:"",
    packageImage:"",
    packageDetails:"",
   
  })

  const handleChange =(e)=>{
    const{name, value} = e.target;
      setPackageAdd({
       ...packageAdd,
       [name]:value
    })
  }

  const handleImage=(e)=>{
    const file = e.target.files[0];
    setPackageAdd({
      ...packageAdd,
      packageImage:file
    }
    )
  }

  useEffect(() => {
    setPackageAdd({
        ...packageAdd,
        packageName:location?.state?.data?.name,
        packageAmount:location?.state?.data?.amount,
        packageImage:location?.state?.data?.image,
        packageDetails:location?.state?.data?.description,
    })
  }, [location]);

  const role_function = async () => {
    if (pagetype == "Add") {
      const fd = new FormData();
      fd.append("name", packageAdd.packageName);
      fd.append("amount", packageAdd.packageAmount);
      fd.append("description", packageAdd.packageDetails);
      fd.append("Image", packageAdd.packageImage);
      

     
   
      try {
        let res = await addPackageaddon_api(fd);
        if (res.data) {
          navigate("/addonpackage-list");
         toast.success("Addon Package created successfully")
        } else {
          toast.warning("Addon Package not created successfully")
        }
      } catch (error) {
        toast.success("Addon Package not created successfully")
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      const fd = new FormData();
      fd.append("name", packageAdd.packageName);
      fd.append("amount", packageAdd.packageAmount);
      fd.append("description", packageAdd.packageDetails);
      fd.append("Image", packageAdd.packageImage);
      fd.append("id", location?.state?.data?._id);
      
      try {
        let res = await updateAddonPackage_api(fd);
        if (res.data) {
          console.log(res);
          navigate("/addonpackage-list");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };
  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Package Name</label>
                <input type="text" className="form-control"
                name="packageName" 
                value={packageAdd.packageName} 
                onChange={handleChange}
                placeholder="packageName" />
              </div>
              
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Package Amount</label>
                <input type="text" className="form-control"
                name="packageAmount" 
                value={packageAdd.packageAmount} 
                onChange={handleChange}
                placeholder="packageAmount" />
              </div>
            </Grid>
            
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Package Details</label>
                <input type="text" className="form-control"
                name="packageDetails" 
                value={packageAdd.packageDetails} 
                onChange={handleChange}
                placeholder="Package Details" />
              </div>
            </Grid>
          
          <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Package Image</label>
                <input 
                type="file"
                className="form-control"
                 name="packageImage" 
                // value={packageAdd.packageImage} 
                onChange={handleImage}
                />
              </div>
              
            </Grid>

            {location?.state?.pagetype==="Edit"?
            <Grid item xs={6}>
            <div className="form-group">
             <img src={packageAdd.packageImage} />
            </div>
            
          </Grid>
            :""}
            </Grid>
          <div className={s["form-login-btn"]} onClick={() => role_function()}>
            <Custombutton>Submit</Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddonPackage;
