import React, { useState, useEffect } from "react";
import s from "./SubCategories.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { addBanner_api, addsubcategory_api, updatecategory_api } from "../api/subcategories";
import { getCategories_api } from "../api/category";
const BannerAdd = () => {
  const navigate = useNavigate();
  const [name, setname] = useState("");
  const location = useLocation();
  const id = location?.state?.data?._id;
  const [CategoriesArry, setCategoriesArry] = useState([]);
  const [categoryId, setcategoryId] = useState("");
  const pagetype = location.state.pagetype;
  useEffect(() => {
    setname(location?.state?.data?.name);
    setcategoryId(location?.state?.data?.category?._id);
  }, [location]);

  const Category_function = async () => {
    if (pagetype == "Add") {
      const temp = {
        name: name,
        categoryId: categoryId,
      };
      try {
        let res = await addsubcategory_api(temp);
        if (!res.data.error) {
          navigate("/sub-categories-list");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      const temp = {
        name: name,
        categoryId: categoryId,
        id: id,
      };

      try {
        let res = await updatecategory_api(temp);
        if (!res.data.error) {
          navigate("/sub-categories-list");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getCategories_function();
  }, []);
  const getCategories_function = async () => {
    try {
      let res = await getCategories_api();
      if (!res.data.error) {
        setCategoriesArry(res?.data?.data?.data);
      } else {
        notificationHandler({ type: "success", msg: res.data.message });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };
  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Select Categories</label>
                <div className="  mr-2">
                  <div className="form-group">
                    <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      value={categoryId}
                      onChange={(e) => setcategoryId(e.target.value)}
                      name="role"
                    >
                      <option value="1">Select Sub Category</option>
                      {CategoriesArry.map((data, index) => (
                        <option value={data._id}>{data.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Sub Category Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                  placeholder="Sub Category Name"
                />
              </div>
            </Grid>
          </Grid>

          <div className={s["form-login-btn"]} onClick={() => Category_function()}>
            <Custombutton>{pagetype == "Add" ? "Submit" : "Update"} </Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default BannerAdd;
