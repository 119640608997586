import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";

const allsubcategories = getBaseUrl() + "service/allsubcategories";
const createsubcategory = getBaseUrl() + "service/createsubcategory";
const updatecategory = getBaseUrl() + "service/updatesubcategory";

export const updatecategory_api = async (data) => {
  let config = {
    method: "post",
    url: updatecategory,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const addsubcategory_api = async (data) => {
  let config = {
    method: "post",
    url: createsubcategory,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const statusUpdate_subcategory_api = async (data) => {
  let config = {
    method: "post",
    url: updatecategory,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const allsubcategories_api = async (data) => {
  let config = {
    method: "post",
    url: allsubcategories,
    headers: { Authorization: `Bearer ${Cookies.get("token")}`, "Content-Type": "application/json" },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
