import React, { useState } from 'react';
import "./Package.css";
import { useNavigate } from 'react-router-dom';
import AddonPackageShows from './AddonPackageShows';


const PackageListing = ({data,}) => {
    const navigate = useNavigate();
    const [activeTab , setactiveTab] = useState(0)

    const handleContinue=(elem)=>{
        navigate("/addonpackage-shows" , {state:{data:elem}})
    }
    
  return (
    <div><div className="" style={{marginTop:'3rem'}}>
    <div className="packList">
    {data.length>0?
    data.map((elem , id)=>{
        return(
            <div className="packContainer" >
            <input type="radio" id="personal" className={`packRadio ${activeTab === id ? "activepackRadio" : "nonactivepackRadio"}`} name="pack" />
            <label className={`pack ${activeTab === id ? "activePack" : "nonactivePack"}`}  htmlFor="personal" onClick={()=>setactiveTab(id)}>
              <div className="header">
                <h2>{elem?.packageName.toUpperCase()}</h2>
              </div>
              <div className="price">
                <p>
                  Starting at
                  <b>{elem?.packageAmount} INR</b>
                </p>
              </div>
              <div className="features">
                <p>
                 {elem.packageDetails[0]}
                </p>
                <ul>
              <li class="active">BestDeal Charges : {elem?.bestDealCharges}</li>
              <li class="active">Chat Charges : {elem?.chatCharges}</li>
              <li class="active">Call Charges : {elem?.callCharges}</li>
              <li class="active">Direction Charges : {elem?.directionCharges}</li>
              <li class="active">Inquery Charges : {elem?.inqueryCharges}</li>
              <li class="active">Other Charges : {elem?.othersCharges}</li>
              <li class="active">Socialmedia Charges : {elem?.socialMediaCharges}</li>
              
            </ul>
            <button className={` ${activeTab === id ? "activebtn" : "nonactivebtn"}`} onClick={()=>handleContinue(elem)}>Continue</button>
              </div>
            </label>
          </div>
        )
    })  
    :""}
      {/* <div className="packContainer">
        <input
          type="radio"
          id="institutional"
          className="packRadio"
          name="pack"
          defaultChecked="checked"
        />
        <label className="pack" htmlFor="institutional">
          <div className="header">
            <h2>Institutional</h2>
          </div>
          <div className="price">
            <p>
              Starting at
              <b>50$</b>
            </p>
          </div>
          <div className="features">
            <p>
              Perfect for organizing personal events such as weddings, baby
              showers, baptems, birthdays.
            </p>
           
          </div>
        </label>
      </div>
      <div className="packContainer">
        <input type="radio" id="social" className="packRadio" name="pack" />
        <label className="pack" htmlFor="social">
          <div className="header">
            <h2>Social &amp; Corporate</h2>
          </div>
          <div className="price">
            <p>
              Starting at
              <b>100$</b>
            </p>
          </div>
          <div className="features">
            <p>
              Perfect for organizing personal events such as weddings, baby
              showers, baptems, birthdays.
            </p>
            
          </div>
        </label>
      </div> */}
    </div>
  </div>
  </div>
  )
}

export default PackageListing