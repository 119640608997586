import React, { useState, useEffect } from "react";
import s from "./testimonial.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { createRole_api, role_update_api } from "../api/role";
import { addTestimonial_api, updateBanner_api, updateTestimonial_api } from "../api/testimonial";
const BannerAdd = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [file, setfile] = useState(null);
 
  const pagetype = location.state.pagetype;

  useEffect(() => {
    setfile(location?.state?.data?.banner_three_image)
  }, [location]);

  const role_function = async () => {
    if (pagetype == "Add") {
      const fd = new FormData();
      fd.append("user_image", file);
      try {
        let res = await addTestimonial_api(fd);
        if (res.data.status) {
          navigate("/banner-list");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      const fd = new FormData();
      fd.append("user_image", file);
      fd.append("id", location?.state?.data?._id);
      try {
        let res = await updateBanner_api(fd);
        if (res.data.status) {
          
          navigate("/banner-list");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };
  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Update Banner</label>
                <div className="mr-2">
                  <img src={file}  width={"100%"}/>
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Update Banner</label>
                <div className="mr-2">
                  <input type="file" className="form-control" name="img" placeholder="" accept="image/*" onChange={(e) => setfile(e.target.files[0])} />
                </div>
              </div>
            </Grid>
            
          </Grid>

          <div className={s["form-login-btn"]} onClick={() => role_function()}>
            <Custombutton>Submit</Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default BannerAdd;
