import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { create_order_coupon } from '../../api/coupon';
import { UserContext } from '../../../App';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function PaymentPopUp({openPaymentModal , setOpenpaymentModal  , setPaymentTypeModal  , setPaymentType,packageData , addonPackage , selecetedLocality ,setPaymentDetails}) {
  const handleClose = () => setOpenpaymentModal(false);
  const {state} = React.useContext(UserContext)

  const handlePayment = async(type)=>{
    let data = {
      packageId : packageData?._id,
      optionId : addonPackage?.length>0?addonPackage?.map((elem)=>elem._id):[],
      v_id : state?.id,
    }
    let res = await create_order_coupon(data);
    if(res.data){
      try {
        setPaymentDetails(res.data.data)
      } catch (error) {
        console.log(error)
      }
    }
    if(type=="cash"){
        setPaymentTypeModal(true)
        setPaymentType(type)
        handleClose()
    }
    if(type=="online"){
        setPaymentTypeModal(true)
        setPaymentType(type)
        handleClose()
    }
    if(type=="check"){
        setPaymentTypeModal(true)
        setPaymentType(type)
        handleClose()
    }
  }

  return (
    <div>
      <Modal
        open={openPaymentModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" className='payment-box' onClick={()=>handlePayment("online")}>
            Online Payment
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2" className='payment-box' onClick={()=>handlePayment("cash")}>
            Cash Payment
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2" className='payment-box' onClick={()=>handlePayment("check")}>
            Cheque
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
