import React, { useEffect, useState } from "react";
import s from "../../Services/services.module.css";
import { Card, CircularProgress, Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import PackageListing from "./PackageListing";
import "./Package.css";
import { getpackage_api } from "../../api/testimonial";

const SelectPackage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [activeTab, setactiveTab] = useState("3");
  const [isLoading, setisLoading] = useState(false);
  const [allpackage, setallpackage] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [pageLength, setpageLength] = useState();
  const [search, setsearch] = useState("");
  const [packageFilter, setPackageFilter] = useState([]);

  useEffect(() => {
    fetchallpackageFunc();
  }, [pageCount, search]);

  async function fetchallpackageFunc(data) {
    // setisLoading(true);
    try {
      const temp = {
        page: pageCount,
        limit: 8,
        search: search.trim(),
      };
      let res = await getpackage_api(temp);
      if (res.data) {
        setpageLength(res?.data?.data?.totalPages);
        setallpackage(res?.data?.data?.data);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const updateFilter = allpackage?.filter((elem, ind) => {
      return +activeTab === elem?.packageDuration;
    });
    setPackageFilter(updateFilter);
  }, [activeTab, allpackage]);

  return (
    <>
      <div className="container">
        <div className="beat_heading">
          <div className="beat_left">
            <div className="title">Select Package </div>
          </div>
          <div className="beat_right"></div>
        </div>

        <div className="lm_content_tab_container">
          <div className="lm_content_tab_wrapper">
            <div
              onClick={() => setactiveTab("3")}
              className={`tabs ${activeTab === "3" ? "active" : ""}`}
            >
              3 Months
            </div>
            <div
              onClick={() => setactiveTab("6")}
              className={`tabs ${activeTab === "6" ? "active" : ""}`}
            >
              6 Months
            </div>
            <div
              onClick={() => setactiveTab("12")}
              className={`tabs ${activeTab === "12" ? "active" : ""}`}
            >
              1 Year
            </div>
          </div>
        </div>

        {isLoading ? (
          <div style={{ margin: "8rem auto" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <PackageListing data={packageFilter} />
          </>
        )}
      </div>
    </>
  );
};

export default SelectPackage;
