import React, { useContext, useEffect, useState } from "react";
import a from "./../../assets/images/datanotfound.gif";
import s from "./role.module.css";
import { GrLocation } from "react-icons/gr";
import { CiEdit, CiWallet } from "react-icons/ci";
import { AiOutlineCamera, AiOutlineClockCircle, AiOutlineSchedule } from "react-icons/ai";
import { TbWorldWww } from "react-icons/tb";
import { MdVerified } from "react-icons/md";
import { BiSolidContact } from "react-icons/bi";
import Expand from "react-expand-animated";
import { Card, Grid } from "@mui/material";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import paymentmodeimg from "../../assets/icons/payment-mode.png";
import facebook from "../../assets/icons/Facebook.png";
import instagram from "../../assets/icons/instagram.png";
import linkedin from "../../assets/icons/linkedin.png";
import pinterest from "../../assets/icons/pinterest.png";
import twitter from "../../assets/icons/twitter.png";
import youtube from "../../assets/icons/youtube.png";
import upload from "../../assets/icons/upload.png";
import {
  adduserdetails_api,
  findvendorbyid_api,
  getcity_api,
  getstate_api,
  sendotp_api,
  updateadderess_vendord_api,
  updateapaymenttype_api,
  updatecontactdetails_api,
  updatetimeslot_vendord_api,
  uploadbanner_api,
  uploaddocuments_api,
  uploadgallary_api,
  uploadsocialmedia_api,
  vendorbackgroundimage_api,
  vendorprofileimage_api,
} from "../api/vendor";
import { useLocation, useNavigate } from "react-router-dom";
import OtpModal from "../../Common/OtpModal";
import { UserContext } from "../../App";

const VendorProfile = () => {
  const {state, dispatch} = useContext(UserContext)
  const location = useLocation();
  const navigate  = useNavigate();
  const { _id, designation, email, mobileNumber, whatsappNumber,
     name, address, startTime, endTime, yearOfEsteblish, paymentType, companyName }
      = location?.state?.data;

  console.log("context state", state)

    useEffect(() => {
      dispatch({type: 'USER', payload: {id: _id, mobileno:mobileNumber}})
    }, [])
    

  const [contactexpandbox, setcontactexpandbox] = useState(false);
  const [otpModalShow , setotpModalShow] = useState(false);
  const [startTimevalue, setstartTimevalue] = useState(convertTo24Hour(startTime));
  const [backgroundimage, setbackgroundimage] = useState("");
  const [profileImage, setprofileImage] = useState("");
  const [endTimevalue, setendTimevalue] = useState(convertTo24Hour(endTime));
  const [gallary, setgallary] = useState([]);
  const [yearOfEsteblishvalue, setyearOfEsteblishvalue] = useState(yearOfEsteblish);
  const [addressxpandbox, setaddressxpandbox] = useState(false);
  const [paymentmethodxpandbox, setpaymentmethodxpandbox] = useState(false);
  const [timeslotexpandbox, settimeslotexpandbox] = useState(false);
  const [linkexpandbox, setlinkexpandbox] = useState(false);
  const [yearexpandbox, setyearexpandbox] = useState(false);
  const [documents, setdocuments] = useState([
    {
      name: "Aadhar",
      type: "Aadhar",
      status: "",
      img: "",
    },
    {
      name: "PAN",
      status: "",
      type: "PAN",
      img: "",
    },
    {
      name: "Company Certificate",
      type: "Company",
      status: "",
      img: "",
    },
    {
      name: "Other Documents",
      type: "Other",
      status: "",
      img: "",
    },
  ]);
  const [contactdetails, setcontactdetails] = useState({
    designation: designation,
    email: email,
    whatsappNumber: whatsappNumber,
    mobileNumber: mobileNumber,
    contactName: name,
    businessId: _id,
  });
  const [vendorBanner , setVendorBanner] = useState('')

  const updatebassicdetails = (e) => {
    setcontactdetails({ ...contactdetails, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    findvendorbyid_function();
  }, [location]);
  const findvendorbyid_function = async () => {
    const temp = {
      id: _id,
    };

    try {
      let res = await findvendorbyid_api(temp);
      if (!res.data.error) {
        setbackgroundimage(res?.data?.data?.vendor?.bgImage);
        setprofileImage(res?.data?.data?.vendor?.profileImage);
        setgallary(res?.data?.data?.gallary);
        setVendorBanner(res?.data?.data?.vendor?.bannerImage)
        const cc = [
          {
            name: "Aadhar",
            status: res?.data?.data?.vendor?.aadharImageVerified,
            type: "Aadhar",
            img: res?.data?.data?.vendor?.aadharImage,
          },
          {
            name: "PAN",
            status: res?.data?.data?.vendor?.panImageVerified,
            type: "PAN",
            img: res?.data?.data?.vendor?.panImage,
          },
          {
            name: "Company Certificate",
            status: res?.data?.data?.vendor?.companyCertificateImageVerified,
            type: "Company",
            img: res?.data?.data?.vendor?.companyCertificateImage,
          },
          {
            name: "Other Documents",
            status: res?.data?.data?.vendor?.otherDocumentImageVerified,
            type: "Other",
            img: res?.data?.data?.vendor?.otherDocumentImage,
          },
        ];
        setdocuments(cc);
        const link = res.data.data.links;
        sociallinks.map((data, i) => {
          if (data.key === "website") {
            sociallinks[i].link = link.website;
          }
          if (data.key === "facebook") {
            sociallinks[i].link = link.facebook;
          }
          if (data.key === "instagram") {
            sociallinks[i].link = link.instagram;
          }
          if (data.key === "twitter") {
            sociallinks[i].link = link.twitter;
          }
          if (data.key === "youtube") {
            sociallinks[i].link = link.youtube;
          }
          if (data.key === "linkedin") {
            sociallinks[i].link = link.linkedin;
          }
          if (data.key === "other") {
            sociallinks[i].link = link.other;
          }
          setsociallinks([...sociallinks]);
        });
        notificationHandler({ type: "success", msg: "Success" });
      } else {
        notificationHandler({ type: "success", msg: "Not Success" });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };

  const [addressdetails, setaddressdetails] = useState({
    buldingName: address[0]?.buldingName,
    streetName: address[0]?.streetName,
    landmark: address[0]?.landmark,
    area: address[0]?.area,
    pinCode: address[0]?.pinCode,
    city: address[0]?.city,
    state: address[0]?.state,
    country: address[0]?.country,
    businessId: _id,
    latitude: "",
    longitude: "",
  });

  const user_details_function = async () => {
    const temp = {
      designation: contactdetails.designation,
      email: contactdetails.email,
      whatsappNumber: contactdetails.whatsappNumber,
      mobileNumber: contactdetails.mobileNumber,
      contactName: contactdetails.contactName,
      businessId: _id,
    };
    try {
      let res = await updatecontactdetails_api(temp);
      if (!res.data.error) {
        notificationHandler({ type: "success", msg: "Success" });
      } else {
        notificationHandler({ type: "success", msg: "Not Success" });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };
  const updateaddress = (e) => {
    setaddressdetails({ ...addressdetails, [e.target.name]: e.target.value });
  };

  const update_address_function = async () => {
    const temp = {
      buldingName: addressdetails.buldingName,
      streetName: addressdetails.streetName,
      landmark: addressdetails.landmark,
      area: addressdetails.area,
      pinCode: addressdetails.pinCode,
      city: cityId,
      state: stateId,
      country: "India",
      businessId: _id,
      latitude: "",
      longitude: "",
    };
    try {
      let res = await updateadderess_vendord_api(temp);
      if (!res.data.error) {
        notificationHandler({ type: "success", msg: "Success" });
      } else {
        notificationHandler({ type: "success", msg: "Not Success" });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };
  const update_timeslot_function = async (type) => {
    const temp = {
      businessId: _id,
      startTime: convertFrom24To12Format(startTimevalue),
      endTime: convertFrom24To12Format(endTimevalue),
    };
    const temp2 = {
      businessId: _id,
      yearOfEsteblish: yearOfEsteblishvalue,
    };

    try {
      let res = await updatetimeslot_vendord_api(type === "time" ? temp : temp2);
      if (!res.data.error) {
        notificationHandler({ type: "success", msg: "Success" });
      } else {
        notificationHandler({ type: "success", msg: "Not Success" });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };
  const updatepaymentmetod = async () => {
    let activePayment = [];
    paymentmode.map((data, i) => {
      if (data.status) {
        activePayment.push(data.name);
      }
    });
    const temp = {
      businessId: _id,
      paymentType: activePayment,
    };

    try {
      let res = await updateapaymenttype_api(temp);
      if (!res.data.error) {
        notificationHandler({ type: "success", msg: "Success" });
      } else {
        notificationHandler({ type: "success", msg: "Not Success" });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };

  const [paymentmode, setpaymentmode] = useState([
    {
      name: "Phone Pay",
      status: false,
    },
    {
      name: "Google Pay",
      status: false,
    },
    {
      name: "Paytm",
      status: false,
    },
    {
      name: "Debit Card",
      status: false,
    },
    {
      name: "Credit Card",
      status: false,
    },
    {
      name: "Net Banking",
      status: false,
    },
    {
      name: "Cash on delivery",
      status: false,
    },
    {
      name: "Check",
      status: false,
    },
    {
      name: "IMPS",
      status: false,
    },
  ]);
  const paymentmodeupdate = (data, i) => {
    paymentmode[i].status = !data.status;
    setpaymentmode([...paymentmode]);
  };
  useEffect(() => {
    paymentstatus();
  }, [location]);
  const paymentstatus = () => {
    paymentmode.map((data, i) => {
      if (paymentType.includes(data.name)) {
        paymentmode[i].status = true;
        setpaymentmode([...paymentmode]);
      }
    });
  };

  const [sociallinks, setsociallinks] = useState([
    {
      name: "Facebook",
      key: "facebook",
      img: facebook,
      link: "",
      status: false,
    },
    {
      name: "Instagram",
      key: "instagram",
      img: instagram,
      link: "",
      status: false,
    },
    {
      name: "Linkedin",
      key: "linkedin",
      img: linkedin,
      link: "",
      status: false,
    },
    {
      name: "Pinterest",
      img: pinterest,
      key: "pinterest",
      link: "",
      status: false,
    },
    {
      name: "Twitter",
      key: "twitter",
      img: twitter,
      link: "",
      status: false,
    },
    {
      name: "Youtube",
      img: youtube,
      key: "youtube",
      link: "",
      status: false,
    },
  ]);

  function convertTo24Hour(timeString) {
    let date = new Date(`01/01/2022 ${timeString}`);
    let formattedTime = date.toLocaleTimeString("en-US", { hour12: false });
    return formattedTime;
  }
  const convertFrom24To12Format = (time24) => {
    const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
    const period = +sHours < 12 ? "AM" : "PM";
    const hours = +sHours % 12 || 12;

    return `${hours}:${minutes} ${period}`;
  };

  const uploadsocialmedia_function = async () => {
    const getLinkForKey = (key) => {
      const linkObject = sociallinks.find((link) => link.key === key);

      if (linkObject) {
        return linkObject.link;
      }
    };
    const temp = {
      businessId: _id,
      website: getLinkForKey("website"),
      facebook: getLinkForKey("facebook"),
      instagram: getLinkForKey("instagram"),
      twitter: getLinkForKey("twitter"),
      youtube: getLinkForKey("youtube"),
      linkedin: getLinkForKey("linkedin"),
      whatsapp: getLinkForKey("whatsapp"),
      snapchat: getLinkForKey("snapchat"),
      other: getLinkForKey("other"),
    };

    try {
      let res = await uploadsocialmedia_api(temp);
      if (!res.data.error) {
        notificationHandler({ type: "success", msg: "Success" });
      } else {
        notificationHandler({ type: "success", msg: "Not Success" });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };

  const editsociallink = (e, data, i) => {
    console.log(e, data, i);
    sociallinks[i].link = e.target.value;
    setsociallinks([...sociallinks]);
  };

  console.log(vendorBanner , "banner")

  const changePicFunc = async (e) => {
    const fd = new FormData();
    fd.append("Image", e.target.files[0]);
    fd.append("id", _id);
    try {
      let res = await uploadgallary_api(fd);
      if (!res.data.error) {
        notificationHandler({ type: "success", msg: "Success" });
        findvendorbyid_function();
      } else {
        notificationHandler({ type: "success", msg: "Not Success" });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };
  const uploadBanner = async (e) => {
    const fd = new FormData();
    fd.append("Image", e.target.files[0]);
    fd.append("id", _id);
    try {
      let res = await uploadbanner_api(fd);
      if (!res.data.error) {
        notificationHandler({ type: "success", msg: "Success" });
        setVendorBanner(res?.data?.data?.bannerImage)
      } else {
        notificationHandler({ type: "success", msg: "Not Success" });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };
  const uploaddocumentsFunc = async (e, type) => {
    const fd = new FormData();
    if (type == "Aadhar") {
      fd.append("Aadhar", e.target.files[0]);
    }
    if (type == "PAN") {
      fd.append("PAN", e.target.files[0]);
    }
    if (type == "Company") {
      fd.append("Company", e.target.files[0]);
    }
    if (type == "Other") {
      fd.append("Other", e.target.files[0]);
    }

    fd.append("id", _id);
    try {
      let res = await uploaddocuments_api(fd);
      if (!res.data.error) {
        notificationHandler({ type: "success", msg: "Success" });
        findvendorbyid_function();
      } else {
        notificationHandler({ type: "success", msg: "Not Success" });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };
  const vendorprofileimage = async (e) => {
    const fd = new FormData();
    fd.append("Image", e.target.files[0]);
    fd.append("id", _id);
    try {
      let res = await vendorprofileimage_api(fd);
      if (!res.data.error) {
        notificationHandler({ type: "success", msg: "Success" });
        findvendorbyid_function();
      } else {
        notificationHandler({ type: "success", msg: "Not Success" });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };
  const vendorbackgroundimage = async (e) => {
    const fd = new FormData();
    fd.append("Image", e.target.files[0]);
    fd.append("id", _id);
    try {
      let res = await vendorbackgroundimage_api(fd);
      if (!res.data.error) {
        notificationHandler({ type: "success", msg: "Success" });
        findvendorbyid_function();
      } else {
        notificationHandler({ type: "success", msg: "Not Success" });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };
  const [getallstate, setgetallstate] = useState([]);
  const [getallcity, setgetallcity] = useState([]);
  const [stateId, setstateId] = useState(addressdetails?.state);
  const [cityId, setcityId] = useState(addressdetails?.city);
  useEffect(() => {
    getstate_function();
  }, []);
  const getstate_function = async (e) => {
    try {
      let res = await getstate_api();
      if (!res.data.error) {
        setgetallstate(res.data.data);
        notificationHandler({ type: "success", msg: "Success" });
      } else {
        notificationHandler({ type: "success", msg: "Not Success" });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };
  const getcity_function = async (stateId) => {
    const temp = {
      stateId: stateId,
    };
    try {
      let res = await getcity_api(temp);
      if (!res.data.error) {
        console.log(res.data.data);
        setgetallcity(res.data.data);
        notificationHandler({ type: "success", msg: "Success" });
      } else {
        notificationHandler({ type: "success", msg: "Not Success" });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };

  const handleFreelisting=async(number)=>{
    const temp = {
      phone:number,
    }
    try {
      let res = await sendotp_api(temp);
      if (!res.data.error) {
        console.log(res.data.data);
        notificationHandler({ type: "success", msg: "Success" });
        setotpModalShow(true)
      } else {
        notificationHandler({ type: "success", msg: "Not Success" });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  }

  return (
    <div>
      {" "}
      <>
        {/* <------user Profile -----> */}
        <div className="outer-div">
          <div className="inner-div">
            <div className="front">
              <div className="front__bkg-photo" style={{ backgroundImage: `url(${backgroundimage ? backgroundimage : a})` }}>
                <label className="vendor-photo-edit">
                  <AiOutlineCamera size={20} className={s["camera_icon"]} />
                  <input type="file" onChange={(e) => vendorbackgroundimage(e)} name="myfile" accept="image/*" style={{ display: "none" }} />
                </label>
              </div>
              <div className="front__face-photo" style={{ backgroundImage: `url(${profileImage ? profileImage : a})` }}>
                <label className="vendor-photo-edit">
                  <AiOutlineCamera size={20} className={s["camera_icon"]} />
                  <input type="file" onChange={(e) => vendorprofileimage(e)} name="myfile" accept="image/*" style={{ display: "none" }} />
                </label>
              </div>

              <div className="front__text">
                <h4 className="paga_font mt-2">{companyName} </h4>
                <p className="text-center"> {addressdetails?.city}</p>
              </div>
            </div>
          </div>
        </div>

        {/* <------user Detils -----> */}

        <div className="outer-div">
          <div className="inner-div">
            <div className="front">
              <div className="p-2" style={{ display: "flex", justifyContent: "space-between" }}>
                <p className="">
                  <BiSolidContact /> Contact Details
                </p>
                <CiEdit size={20} onClick={() => setcontactexpandbox(!contactexpandbox)} />
              </div>
              <Expand open={contactexpandbox}>
                <Card className={s["admin_container"]}>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="exampleInputEmail1">Contact Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="contactName"
                          value={contactdetails.contactName}
                          onChange={(e) => updatebassicdetails(e)}
                          placeholder="Contact Name"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="exampleInputEmail1">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          value={contactdetails.email}
                          onChange={(e) => updatebassicdetails(e)}
                          placeholder="Email"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="exampleInputEmail1">Mobile Number</label>
                        <input
                          type="text"
                          className="form-control"
                          name="mobileNumber"
                          value={contactdetails.mobileNumber}
                          onChange={(e) => updatebassicdetails(e)}
                          placeholder="Mobile Number"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="exampleInputEmail1">Whatsapp Number</label>
                        <input
                          type="text"
                          className="form-control"
                          name="whatsappNumber"
                          value={contactdetails.whatsappNumber}
                          onChange={(e) => updatebassicdetails(e)}
                          placeholder="Whatsapp Number"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="exampleInputEmail1">Designation</label>
                        <input
                          type="text"
                          className="form-control"
                          name="designation"
                          value={contactdetails.designation}
                          onChange={(e) => updatebassicdetails(e)}
                          placeholder="Designation"
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <div className={s["form-login-btn"]} onClick={() => user_details_function()}>
                    <Custombutton>Save </Custombutton>
                  </div>
                </Card>
              </Expand>
            </div>
            <div className="front">
              <div className="p-2" style={{ display: "flex", justifyContent: "space-between" }}>
                <p className="">
                  <GrLocation /> Shop No. 2, Main Road, Noida Sector 63, delhi - 201301
                </p>
                <CiEdit size={20} onClick={() => setaddressxpandbox(!addressxpandbox)} />
              </div>
              <Expand open={addressxpandbox}>
                <Card className={s["admin_container"]}>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="exampleInputEmail1">Bulding Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="buldingName"
                          value={addressdetails.buldingName}
                          onChange={(e) => updateaddress(e)}
                          placeholder="Bulding Name"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="exampleInputEmail1">Street Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="streetName"
                          onChange={(e) => updateaddress(e)}
                          value={addressdetails.streetName}
                          placeholder="Street Name"
                        />
                      </div>
                    </Grid>

                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="exampleInputEmail1">Country</label>
                        <input
                          type="text"
                          className="form-control"
                          name="country"
                          onChange={(e) => updateaddress(e)}
                          disabled
                          value={addressdetails.country}
                          placeholder="Country"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="exampleInputEmail1">State</label>
                        <select
                          className="form-control"
                          id="exampleFormControlSelect1"
                          value={stateId}
                          onChange={(e) => {
                            setstateId(e.target.value);
                            setcityId("");
                            getcity_function(e.target.value);
                          }}
                          name="role"
                        >
                          <option>Select State</option>
                          {getallstate.map((data, index) => (
                            <option value={data._id}>{data.name}</option>
                          ))}
                        </select>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="exampleInputEmail1">City</label>
                        <select
                          className="form-control"
                          id="exampleFormControlSelect1"
                          value={cityId}
                          onChange={(e) => {
                            setcityId(e.target.value);
                          }}
                          name="role"
                        >
                          <option>Select City</option>
                          {getallcity.map((data, index) => (
                            <option value={data._id}>{data.name}</option>
                          ))}
                        </select>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="exampleInputEmail1">Landmark</label>
                        <input
                          type="text"
                          className="form-control"
                          name="landmark"
                          value={addressdetails.landmark}
                          onChange={(e) => updateaddress(e)}
                          placeholder="Landmark"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="exampleInputEmail1">Area</label>
                        <input
                          type="text"
                          className="form-control"
                          name="area"
                          value={addressdetails.area}
                          onChange={(e) => updateaddress(e)}
                          placeholder="Area"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="exampleInputEmail1">PinCode</label>
                        <input
                          type="text"
                          className="form-control"
                          name="pinCode"
                          value={addressdetails.pinCode}
                          onChange={(e) => updateaddress(e)}
                          placeholder="PinCode"
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <div className={s["form-login-btn"]} onClick={() => update_address_function()}>
                    <Custombutton>Save </Custombutton>
                  </div>
                </Card>
              </Expand>
            </div>
            <div className="front">
              <div className="p-2" style={{ display: "flex", justifyContent: "space-between" }}>
                <p className="">
                  <AiOutlineClockCircle /> Open now 9:00 am - 8:00 pm
                </p>
                <CiEdit size={20} onClick={() => settimeslotexpandbox(!timeslotexpandbox)} />
              </div>
              <Expand open={timeslotexpandbox}>
                <Card className={s["admin_container"]}>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="exampleInputEmail1">Start Time</label>
                        <input
                          type="time"
                          className="form-control"
                          value={startTimevalue}
                          onChange={(e) => setstartTimevalue(e.target.value)}
                          placeholder="Start Time"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="exampleInputEmail1">End Time</label>

                        <input
                          type="time"
                          className="form-control"
                          value={endTimevalue}
                          onChange={(e) => setendTimevalue(e.target.value)}
                          placeholder="End Time"
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <div className={s["form-login-btn"]} onClick={() => update_timeslot_function("time")}>
                    <Custombutton>Save </Custombutton>
                  </div>
                </Card>
              </Expand>
            </div>
            <div className="front">
              <div className="p-2" style={{ display: "flex", justifyContent: "space-between" }}>
                <p className="">
                  <CiWallet size={20} /> Cash, UPI, Phonepe, Paytm, Credit Card, Debit Card, IMPS, Net Banking.....
                </p>
                <CiEdit size={20} onClick={() => setpaymentmethodxpandbox(!paymentmethodxpandbox)} />
              </div>
              <Expand open={paymentmethodxpandbox}>
                <Card className={s["admin_container"]}>
                  <div className="payment_container">
                    {paymentmode.map((data, i) => (
                      <div className={s["payment-layout"]}>
                        <div className="frame">
                          <div className="text-wrapper">{data.name}</div>
                        </div>
                        <div style={{ position: "relative" }} onClick={() => paymentmodeupdate(data, i)}>
                          <div className={s["ellips-div"]}></div>
                          <div className={s["payment-mode"]}>{data.status && <img src={paymentmodeimg} />}</div>
                        </div>
                      </div>
                    ))}
                    <div className={s["form-login-btn"]} onClick={() => updatepaymentmetod()}>
                      <Custombutton>Save </Custombutton>
                    </div>
                  </div>
                </Card>
              </Expand>
            </div>
            <div className="front">
              <div className="p-2" style={{ display: "flex", justifyContent: "space-between" }}>
                <p className="">
                  <AiOutlineSchedule size={20} /> 15 Years
                </p>
                <CiEdit size={20} onClick={() => setyearexpandbox(!yearexpandbox)} />
              </div>
              <Expand open={yearexpandbox}>
                <Card className={s["admin_container"]}>
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label for="exampleInputEmail1">Year</label>
                        <input
                          type="text"
                          className="form-control"
                          value={yearOfEsteblishvalue}
                          onChange={(e) => setyearOfEsteblishvalue(e.target.value)}
                          placeholder="Enter Year"
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <div className={s["form-login-btn"]} onClick={() => update_timeslot_function("year")}>
                    <Custombutton>Save </Custombutton>
                  </div>
                </Card>
              </Expand>
            </div>
            <div className="front">
              <div className="p-2" style={{ display: "flex", justifyContent: "space-between" }}>
                <p className="">
                  <TbWorldWww size={20} />
                  www.homeshiftingmart.com
                </p>
                <CiEdit size={20} onClick={() => setlinkexpandbox(!linkexpandbox)} />
              </div>
              <Expand open={linkexpandbox}>
                <Card className={s["admin_container"]}>
                  <div className="payment_container">
                    {sociallinks.map((data, i) => (
                      <div className={s["payment-layout"]} style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                        <div className="frame">
                          <div className="overlap-group" style={{ width: "40px", margin: "auto" }}>
                            <img className="vector" alt="Vector" src={data.img} />
                          </div>
                          <div className="text-wrapper">{data.name}</div>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={data?.link}
                          placeholder={`${data.name} Link`}
                          style={{ flexGrow: "1" }}
                          onChange={(e) => editsociallink(e, data, i)}
                        />
                        <div style={{ position: "relative" }} onClick={() => paymentmodeupdate(data, i)}>
                          <div className={s["ellips-div"]}></div>
                          <div className={s["payment-mode"]}>{data.link && <img src={paymentmodeimg} />}</div>
                        </div>
                      </div>
                    ))}
                    <div className={s["form-login-btn"]} onClick={() => uploadsocialmedia_function()}>
                      <Custombutton>Save </Custombutton>
                    </div>
                  </div>
                </Card>
              </Expand>
            </div>
            <div className="front">
              <div className="p-2" style={{ display: "flex", justifyContent: "space-between" }}>
                <p className="">Services Provide in</p>
                <CiEdit size={20} />
              </div>
            </div>
            <div className="front">
              <div className="p-2">
                <p className="">Photos and Videos</p>
                <div className={s["photo-container"]}>
                  <>
                    <div className="image-layout-custom" style={{ position: "relative" }}>
                      <img src={upload} style={{ height: "80px", width: "80px" }} />
                      <input
                        type="file"
                        style={{ height: "80px", width: "80px", borderRadius: "4px", opacity: "0", position: "absolute", top: "0", cursor: "pointer" }}
                        onChange={(e) => changePicFunc(e)}
                        name="myfile"
                      />
                    </div>
                    {gallary.map((data, index) => (
                      <div className="image-layout">
                        {data?.image?.split(".").pop() !== "mp4" && <img style={{ height: "100px", width: "150px", borderRadius: "4px" }} src={data?.image} />}
                        {data?.image?.split(".").pop() === "mp4" && (
                          <video width="150" height="100" controls>
                            <source src={data?.image} type="video/mp4" />
                          </video>
                        )}
                      </div>
                    ))}
                  </>
                </div>
              </div>
            </div>
            <div className="front">
              <div className="p-2">
                <p className="">
                  Verify Documents <MdVerified size={20} />
                </p>

                <div className="photo-container" style={{ display: "flex", gap: "1rem", marginBlock: "0.6rem" }}>
                  {documents?.map((data, index) => (
                    <div className="image-layout" style={{ textAlign: "center" }}>
                      <img style={{ height: "80px", width: "80px", borderRadius: "4px" }} src={data?.img} />
                      <p>{data?.name}</p>
                      <div class="choose_file">
                        <span>Choose File</span>
                        <input name="Select File" type="file" accept="image/png, image/jpeg" onChange={(e) => uploaddocumentsFunc(e, data.type)} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="front">
              <div className="p-2">
                <p className="">Upload Banner</p>
                <div className={s["photo-container"]}>
                  <>
                    <div className="image-layout-custom" style={{ position: "relative" }}>
                      <img src={vendorBanner?vendorBanner:upload} style={{ height: "80px", width: "80px" }} />
                      <input
                        type="file"
                        style={{ height: "80px", width: "80px", borderRadius: "4px", opacity: "0", position: "absolute", top: "0", cursor: "pointer" }}
                        onChange={(e) => uploadBanner(e)}
                        name="myfile"
                      />
                    </div>
                    
                  </>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-section">
            <div className={s["user-list-title"]} style={{ display: "flex", gap: "1rem", marginBlock: "2rem", justifyContent: "center" }}>
              <div className="beat_left">
                <div className={s["title"]} onClick={()=>handleFreelisting(contactdetails.mobileNumber)}>Free lisitng</div>
              </div>
              <div className="beat_left">
                <div className={s["title"]} onClick={()=>navigate("/select-package")}>Paid lisitng</div>
              </div>
            </div>
          </div>
        </div>
        <OtpModal otpModalShow={otpModalShow} setotpModalShow={setotpModalShow} mobileNo={contactdetails.mobileNumber}/>
      </>
    </div>
  );
};

export default VendorProfile;
