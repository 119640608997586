import React, { useState, useEffect } from "react";
import s from "./services.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { createservice_api, update_service_api } from "../api/service";
const AddSkill = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setname] = useState();
  const [formdata, setformdata] = useState({
    name: "",
    image: "",
  });

  const pagetype = location?.state?.pagetype;
  const id = location?.state?.data?._id;
  useEffect(() => {
    setformdata({
      name: location?.state?.data?.name,
      image: "",
    });
  }, [location]);

  const create_event = async () => {
    if (pagetype == "Add") {
      try {
        const fd = new FormData();
        fd.append("name", formdata.name);
        fd.append("Image", formdata.image);
        let res = await createservice_api(fd);
        if (!res.data.error) {
          console.log(res);
          navigate("/service-list");
          notificationHandler({ type: "success", msg: res.data.data });
        } else {
          notificationHandler({ type: "success", msg: res.data.errormessage });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      const fd = new FormData();
      fd.append("name", formdata.name);
      fd.append("Image", formdata.image);
      fd.append("id", id);
      try {
        let res = await update_service_api(fd);
        if (!res.data.error) {
          navigate("/service-list");
          notificationHandler({ type: "success", msg: res?.data?.msg });
        } else {
          notificationHandler({ type: "success", msg: res?.data?.errormessage });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error?.message });
        console.log(error);
      }
    }
  };

  const formdatafunction = (e) => {
    if (e.target.name === "image") {
      setformdata({
        ...formdata,
        [e.target.name]: e.target.files[0],
      });
      return;
    }
    setformdata({
      ...formdata,
      [e.target.name]: e.target.value,
    });
  };
  console.log(formdata);
  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Name</label>
                <input type="text" className="form-control" name="name" value={formdata.name} onChange={(e) => formdatafunction(e)} placeholder="Name" />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Select Image</label>
                <div className="mr-2">
                  <input type="file" className="form-control" name="image" placeholder="" accept="image/*" onChange={(e) => formdatafunction(e)} />
                </div>
              </div>
            </Grid>
          </Grid>

          <div className={s["form-login-btn"]} onClick={() => create_event()}>
            <Custombutton>{pagetype == "Add" ? "Submit" : "Update"} </Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddSkill;
