import React, { useState, useEffect } from "react";
import s from "../Banner/testimonial.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
// import { createRole_api, role_update_api } from "../api/role";
import { addPackage_api, updatePackage_api, updateTestimonial_api } from "../api/testimonial";
import RichTextEditor from "react-rte-17"
const PackageAdd = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pagetype = location.state.pagetype;

  const [packageAdd , setPackageAdd] = useState({
    packageName:"",
    packageAmount:"",
    packageDuration:"",
    callCharges: "",
    socialMediaCharges: "",
    bestDealCharges:"",
    directionCharges:"",
    inqueryCharges:"",
    othersCharges:"",
    chatCharges:"",
    websiteCharges:"",
  
  })
  const [packageDetails , setPackageDetails] = useState([])
  const [rte, setrte] = useState(RichTextEditor.createEmptyValue())

  const handleChange =(e)=>{
    const{name, value} = e.target;
   setPackageAdd({
    ...packageAdd,
    [name]:value
})
  }

  useEffect(() => {
    setPackageDetails(location?.state?.data?.packageDetails[0])
    setPackageAdd({
        ...packageAdd,
        packageName:location?.state?.data?.packageName,
        packageAmount:location?.state?.data?.packageAmount,
        packageDuration:location?.state?.data?.packageDuration,
        callCharges: location?.state?.data?.callCharges,
        socialMediaCharges: location?.state?.data?.socialMediaCharges,
        bestDealCharges:location?.state?.data?.bestDealCharges,
        directionCharges:location?.state?.data?.directionCharges,
        inqueryCharges:location?.state?.data?.inqueryCharges,
        othersCharges:location?.state?.data?.othersCharges,
        chatCharges:location?.state?.data?.chatCharges,
        websiteCharges:location?.state?.data?.websiteCharges,

        // setrte:location?.state?.data?.packageDetails,
        
    })
    
  }, [location]);

  const role_function = async () => {
    if (pagetype == "Add") {
    let packagePayload = {
     packageDetails,
    ...packageAdd
   }
      try {
        let res = await addPackage_api(packagePayload);
        if (!res.data.error) {
          navigate("/package-list");
          notificationHandler({ type: "success", msg: res.data.message });
        } else {
          notificationHandler({ type: "success", msg: res.data.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      let packagePayload = {
        packageDetails,
        ...packageAdd
       }
       let id  = location?.state?.data?._id;
      try {
        let res = await updatePackage_api(packagePayload , id);
        if (!res.data.error) {
          console.log(res);
          navigate("/package-list");
          notificationHandler({ type: "success", msg: "Update successfully" });
        } else {
          notificationHandler({ type: "success", msg: "Not updated" });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
    }
  };

  const onChange = (value) => {
    setrte(value);
    if (props.onChange) {
      props.onChange(value.toString("html"));
    }
  };

  console.log(packageDetails , "package")
 
  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              {/* <div className="form-group">
                <label for="exampleInputEmail1">Package Name</label>
                <input type="text" className="form-control"
                name="packageName" 
                value={packageAdd.packageName} 
                onChange={handleChange}
                placeholder="packageName" />
              </div> */}
              <div className="form-group">
                  <label for="exampleInputEmail1">Choose Package</label>
                  <div className="  mr-2">
                    <div className="form-group">
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        value={packageAdd.packageName}
                        onChange={handleChange}
                        name="packageName"
                      >
                        <option value="">Choose Package</option>
                          <option value="gold">Gold</option>
                          <option value="silver">Silver</option>
                          <option value="platinum">Platinum</option>
                      </select>
                    </div>
                  </div>
                </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Package Amount</label>
                <input type="text" className="form-control"
                name="packageAmount" 
                value={packageAdd.packageAmount} 
                onChange={handleChange}
                placeholder="packageAmount" />
              </div>
            </Grid>
            <Grid item xs={6}>
              {/* <div className="form-group">
                <label for="exampleInputEmail1">Package Duration</label>
                <input type="text" className="form-control"
                name="packageDuration" 
                value={packageAdd.packageDuration} 
                onChange={handleChange}
                placeholder="packageDuration" />
              </div> */}
              <div className="form-group">
                  <label for="exampleInputEmail1">Choose Package Duration</label>
                  <div className="mr-2">
                    <div className="form-group">
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        value={packageAdd.packageDuration}
                        onChange={handleChange}
                        name="packageDuration"
                      >
                        <option value="">Choose Package Duration</option>
                          <option value="3">3 Months</option>
                          <option value="6">6 Months</option>
                          <option value="12">12 Months</option>
                          <option value="36">3 Years</option>
                      </select>
                    </div>
                  </div>
                </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Call Charges</label>
                <input type="text" className="form-control"
                name="callCharges" 
                value={packageAdd.callCharges} 
                onChange={handleChange}
                placeholder="callCharges" />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Package details</label>
                <input type="text" className="form-control"
                name="packageDetails" 
                value={packageDetails} 
                onChange={(e)=>setPackageDetails([ e.target.value])}
                placeholder="packageDetails" />
              </div>
            </Grid>
            {/* <Grid item xs={12}>
              <div className="form-group">
                <label for="exampleInputEmail1">Package Details</label>
               
                  <RichTextEditor value={rte} className="employment_jobdescribee" onChange={onChange} />
              </div>
            </Grid> */}
           
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Social Media Charges</label>
                <input type="text" className="form-control"
                name="socialMediaCharges" 
                value={packageAdd.socialMediaCharges} 
                onChange={handleChange}
                placeholder="socialMediaCharges" />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Best Deal Charges</label>
                <input type="text" className="form-control"
                name="bestDealCharges" 
                value={packageAdd.bestDealCharges} 
                onChange={handleChange}
                placeholder="bestDealCharges" />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Direction Charges</label>
                <input type="text" className="form-control"
                name="directionCharges" 
                value={packageAdd.directionCharges} 
                onChange={handleChange}
                placeholder="directionCharges" />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Inquiry Charges</label>
                <input type="text" className="form-control"
                name="inqueryCharges" 
                value={packageAdd.inqueryCharges} 
                onChange={handleChange}
                placeholder="inqueryCharges" />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Other Charges</label>
                <input type="text" className="form-control"
                name="othersCharges" 
                value={packageAdd.othersCharges} 
                onChange={handleChange}
                placeholder="othersCharges" />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Chat Charges</label>
                <input type="text" className="form-control"
                name="chatCharges" 
                value={packageAdd.chatCharges} 
                onChange={handleChange}
                placeholder="chatCharges" />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Website Charges</label>
                <input type="text" className="form-control"
                name="websiteCharges" 
                value={packageAdd.websiteCharges} 
                onChange={handleChange}
                placeholder="websiteCharges" />
              </div>
            </Grid>
            
            
            
          </Grid>

          <div className={s["form-login-btn"]} onClick={() => role_function()}>
            <Custombutton>Submit</Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default PackageAdd;
