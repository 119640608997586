import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "../Banner/testimonial.module.css";
import { useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import DataNotFound from "../ErrorPage/DataNotFound";
import { BiFilter, BiSearch } from "react-icons/bi";
import DeletePopup from "../Dialogbox/DeletePopup";
import FilterPopup from "../Dialogbox/FilterPopup";
import {
  deletePackage_api,
  deleteTestimonial_api,
  getpackage_api,
  status_updateTestimonial_api,
} from "../api/testimonial";

const PackageList = () => {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [allpackage, setallpackage] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [deleteId, setdeleteId] = useState();
  const [deletedialobbox, setdeletedialobbox] = useState(false);
  const [deletename, setdeletename] = useState();
  const [pageLength, setpageLength] = useState();
  const [search, setsearch] = useState("");
  useEffect(() => {
    fetchallpackageFunc();
  }, [pageCount, search]);

  async function fetchallpackageFunc(data) {
    setisLoading(true);
    try {
      const temp = {
        page: pageCount,
        limit: 8,
        search: search.trim(),
      };
      let res = await getpackage_api(temp);
      if (res.data) {
        setpageLength(res?.data?.data?.data?.totalPages);
        setpageCount(res?.data?.data?.data?.totalData);
        setallpackage(res?.data?.data?.data);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--clr-theme)",
      color: theme.palette.common.white,
      fontWeight: "bold",
      borderRight: "1px solid #fff",
      overflow: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      borderLeft: "2px solid #00000011",
      "&:last-child": {
        borderRight: "2px solid #00000011",
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "2px solid #00000011",
  }));

  async function deleteroleFunc() {
    setisLoading(true);
    let temp = {
      id: deleteId,
    };
    try {
      let res = await deletePackage_api(temp);
      console.log(res);
      if (!res.data.error) {
        setisLoading(false);
        setdeletedialobbox(false);
        fetchallpackageFunc();
        notificationHandler({ type: "success", msg: res.data.data });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.data });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.data });
    }
  }

  const testimonial_status = async (data, e) => {
    setisLoading(true);

    const fd = new FormData();
    fd.append("id", data.id);
    fd.append("status", data.status == true ? false : true);
    try {
      let res = await status_updateTestimonial_api(fd);
      console.log(res);
      if (res.data.status) {
        setisLoading(false);
        fetchallpackageFunc();
        notificationHandler({ type: "success", msg: res.data.message });
      } else {
        setisLoading(false);
        notificationHandler({ type: "danger", msg: res.data.message });
      }
    } catch (error) {
      console.log(error);
      notificationHandler({ type: "danger", msg: error.message });
    }
  };

  return (
    <div className="container">
      <div className={s["role-list-title"]}>
        <h3>Package List</h3>
      </div>
      <div className={s["user-list-heading"]}>
        <div className={s["user-list-title"]}>
          <div className="beat_left">
            <div
              className={s["title"]}
              onClick={() =>
                navigate("/package-add", {
                  state: {
                    pagetype: "Add",
                  },
                })
              }
            >
              <IoMdAdd /> Add
            </div>
          </div>
        </div>
        <div className={s["user-list-search"]}>
          <div className={s["search-box"]}>
            <span style={{ paddingRight: "0.5rem" }}>
              <BiSearch size={23} />
            </span>
            <input
              type="text"
              spellCheck="false"
              onChange={(e) => {
                setpageCount(1);
                setsearch(e.target.value);
              }}
              placeholder="Search name..."
            />
          </div>
          <div className={s["filter-btn"]}>
            <span style={{ paddingRight: "2px" }}>
              <BiFilter size={20} />
            </span>
            Filter
          </div>
        </div>
      </div>
      <div className="beat_table">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">S.No.</StyledTableCell>
              <StyledTableCell align="center">PackageName</StyledTableCell>
              <StyledTableCell align="center">PackageAmount</StyledTableCell>
              <StyledTableCell align="center">PackageDuration</StyledTableCell>
              <StyledTableCell align="center">PackageDetails</StyledTableCell>
              {/* <StyledTableCell align="center">CallCharges</StyledTableCell>
              <StyledTableCell align="center">SocialMediaCharges</StyledTableCell>
              <StyledTableCell align="center">WebsiteCharges</StyledTableCell>
              <StyledTableCell align="center">DirectionCharges</StyledTableCell>
              <StyledTableCell align="center">InquireCharges</StyledTableCell>
              <StyledTableCell align="center">ChatCharegs</StyledTableCell>
              <StyledTableCell align="center">BestDealCharges</StyledTableCell> */}
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allpackage?.map((row, id) => (
              <StyledTableRow key={row?.id}>
                <StyledTableCell align="center">{id + 1}</StyledTableCell>
                <StyledTableCell>
                  {row?.packageName.toUpperCase()}
                </StyledTableCell>
                <StyledTableCell>{row?.packageAmount}</StyledTableCell>
                <StyledTableCell>{row?.packageDuration}</StyledTableCell>
                {/* <StyledTableCell><div dangerouslySetInnerHTML={{__html:row.packageDetails}}></div></StyledTableCell> */}
                <StyledTableCell>{row?.packageDetails?.[0]}</StyledTableCell>
                {/* <StyledTableCell>{row.callCharges}</StyledTableCell>
              <StyledTableCell>{row.socialMediaCharges}</StyledTableCell>
              <StyledTableCell>{row.websiteCharges}</StyledTableCell>
              <StyledTableCell>{row.directionCharges}</StyledTableCell>
              <StyledTableCell>{row.inqueryCharges}</StyledTableCell>
              <StyledTableCell>{row.chatCharges}</StyledTableCell>
              <StyledTableCell>{row.bestDealCharges}</StyledTableCell> */}
                {/* <StyledTableCell align="center">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => testimonial_status(row)}
                    className={`${row.is_active === true ? s.active_admin : s.inactive_admin}`}
                  >
                    {`${row.status === true ? "Active" : "InActive"}`}
                  </div>
                </StyledTableCell> */}
                <StyledTableCell align="center">
                  <CiEdit
                    onClick={() =>
                      navigate("/package-add", {
                        state: {
                          pagetype: "Edit",
                          data: row,
                        },
                      })
                    }
                    style={{
                      fontSize: "1rem",
                      color: "var(--clr-primary)",
                      marginRight: "0.5rem",
                      cursor: "pointer",
                    }}
                  />
                  <MdDelete
                    onClick={() => {
                      setdeletedialobbox(true);
                      setdeletename(row?.name);
                      setdeleteId(row?._id);
                    }}
                    style={{
                      fontSize: "1rem",
                      color: "var(--clr-primary)",
                      cursor: "pointer",
                    }}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {allpackage?.length <= 0 && <DataNotFound />}
        {allpackage?.length > 0 && (
          <div className={s["pagination"]}>
            <Pagination
              count={pageLength}
              size="large"
              style={{ color: "#D21903" }}
              onChange={(e, value) => setpageCount(value)}
              page={pageCount}
            />
          </div>
        )}
      </div>

      <DeletePopup
        open={deletedialobbox}
        name={deletename}
        close={() => setdeletedialobbox(!deletedialobbox)}
        onsubmit={() => {
          deleteroleFunc();
        }}
      />
      <FilterPopup open={false} />

      <Loder loading={isLoading} />
    </div>
  );
};

export default PackageList;
