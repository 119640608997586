import React, { useState, useEffect } from "react";
import s from "../Services/services.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { createservice_api, update_service_api } from "../api/service";
import { createlocality_api, getcity_api, getstate_api } from "../api/locality";
const AddLocality = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setstate] = useState([]);
  const [stateName , setStateName] = useState([])
  const [cityName , setcityName] = useState([])
  const [city , setCity] = useState([])
  const [locality , setLocality] = useState("")


  const pagetype = location?.state?.pagetype;
  const id = location?.state?.data?._id;

 

  useEffect(() => {
    setLocality(location?.state?.data?.name)
  }, [location]);

  const addLocality = async () => {
    if (pagetype == "Add") {
      let temp  = {
        cityId:city,
        name:locality
      }
      try {
        
        let res = await createlocality_api(temp);
        if (!res.data.error) {
         
          navigate("/locality-list");
          notificationHandler({ type: "success", msg: res.data.errormessage });
        } else {
          notificationHandler({ type: "success", msg: res.data.errormessage });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.errormessage });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      const fd = new FormData();
      fd.append("id", id);
      try {
        let res = await update_service_api(fd);
        if (!res.data.error) {
          navigate("/service-list");
          notificationHandler({ type: "success", msg: res?.data?.msg });
        } else {
          notificationHandler({ type: "success", msg: res?.data?.errormessage });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error?.message });
        console.log(error);
      }
    }
  };

  const getstate_function = async () => {
   
    try {
      let res = await getstate_api();
      if (!res.data.error) {
        setStateName(res.data.data);
      } else {
        notificationHandler({ type: "success", msg: res.data.message });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };

  const getcity_function = async (id) => {
    const temp = {
      stateId: id,
    };
    try {
      let res = await getcity_api(temp);
      if (!res.data.error) {
        setcityName(res.data.data);
      } else {
        notificationHandler({ type: "success", msg: res.data.message });
      }
    } catch (error) {
      notificationHandler({ type: "danger", msg: error.message });
      console.log(error);
    }
  };

  useEffect(()=>{
    getstate_function()
  },[])
  
  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
            <div className="form-group">
                  <label for="exampleInputEmail1">State</label>
                  <div className="  mr-2">
                    <div className="form-group">
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        value={state}
                        onChange={(e) => {
                          setstate(e.target.value);
                          setCity("");
                          getcity_function(e.target.value);
                        }}
                        name="role"
                      >
                        <option value="1">Select City</option>
                        {stateName?.map((data, index) => (
                          <option value={data._id}>{data.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
            </Grid>

            <Grid item xs={6}>
            <div className="form-group">
                  <label for="exampleInputEmail1">City</label>
                  <div className="  mr-2">
                    <div className="form-group">
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        value={city}
                        onChange={(e) => {
                          setCity(e.target.value);
                        }}
                        name="role"
                      >
                        <option value="1">Select State</option>
                        {cityName.map((data, index) => (
                          <option value={data._id}>{data.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
            </Grid>

            <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Locality Name</label>
                <input type="text" className="form-control"
                name="packageDetails" 
                value={locality} 
                onChange={(e)=>setLocality(e.target.value)}
                placeholder="packageDetails" />
              </div>
            </Grid>
          </Grid>

          <div className={s["form-login-btn"]} onClick={() => addLocality()}>
            <Custombutton>{pagetype == "Add" ? "Submit" : "Update"} </Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddLocality;
