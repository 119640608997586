import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import s from "../../Services/services.module.css";
import { Card } from "react-bootstrap";
import { Grid } from "@mui/material";
import Custombutton from "../../../Common/Custombutton";
import OtpModal from "../../../Common/OtpModal";
import { UserContext } from "../../../App";
import { notificationHandler } from "../../../utils/Notification";
import { sendotp_api } from "../../api/vendor";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
 width: "40%",
  bgcolor: "background.paper",
  border: "2px solid #000",
//   boxSha12ow: 24,
  p: 4,
  height: "90%",
  overflowY:"scroll"
};

export default function PaymentTypePopUp({paymentType,paymentTypeModal,setPaymentTypeModal , paymentDetails}) {
  const {state } = React.useContext(UserContext);

    const [checkDetails , setCheckdetails] = React.useState({
        accountHolderName:"",
        bankName:"",
        ifsc:"",
        micr:"",
        accountNo:'',
        chequeNo:"",
        accountType:""
    })
    const [otpModalShow , setotpModalShow] = React.useState(false);

    const handleChange=(e)=>{
        const {name , value} = e.target;
        setCheckdetails({
            ...state,
            [name]:value
        })
    }

  const handleClose = () =>{
    setPaymentTypeModal(false);
  } 

  const handleDealClose = async () => {
    setPaymentTypeModal(false);
    setotpModalShow(true)
    
      const temp = {
        phone:state?.mobileno,
      }
      try {
        let res = await sendotp_api(temp);
        if (!res.data.error) {
          console.log(res.data.data);
          notificationHandler({ type: "success", msg: "Success" });
          setotpModalShow(true)
        } else {
          notificationHandler({ type: "success", msg: "Not Success" });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error.message });
        console.log(error);
      }
  };

  return (
    <div>
      <Modal
        open={paymentTypeModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={s["article-list-title"]}>
            <h3>{paymentType.toUpperCase()} Payment</h3>
          </div>
          <hr />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0rem",
            }}
          >
            <div>Basic Package Amount</div>
            <div>{paymentDetails?.amount}</div>
          </div>

          <hr />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0rem",
            }}
          >
            <div>Amount</div>
            <div>{paymentDetails?.amount}</div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0rem",
            }}
          >
            <div>GST(18%)</div>
            <div>+ {paymentDetails?.gstAmount}</div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0rem",
            }}
          >
            <div>Discount (10%)</div>
            <div>- {paymentDetails?.discount}</div>
          </div>
          <hr />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 0rem",
            }}
          >
            <div>Total</div>
            <div>{paymentDetails?.paidAmount}</div>
          </div>

          {paymentType==="check"?
        <div className={s["card-header"]}>
        <div className={s["title"]}>Cheque Details</div>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <div className="form-group">
              <label for="exampleInputEmail1">Account Holder Name</label>
              <input type="text" className="form-control" name="accountHolderName" value={checkDetails.accountHolderName} onChange={handleChange} placeholder="Name" />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="form-group">
              <label for="exampleInputEmail1">Bank Account Number</label>
              <input type="text" className="form-control" name="accountNo" value={checkDetails.accountNo} onChange={handleChange} placeholder="Name" />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="form-group">
              <label for="exampleInputEmail1">Bank Name</label>
              <input type="text" className="form-control" name="bankName" value={checkDetails.bankName} onChange={handleChange} placeholder="Name" />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="form-group">
              <label for="exampleInputEmail1">Cheque Number</label>
              <input type="text" className="form-control" name="chequeNo" value={checkDetails.chequeNo} onChange={handleChange} placeholder="Name" />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="form-group">
              <label for="exampleInputEmail1">IFSC Code</label>
              <input type="text" className="form-control" name="ifsc" value={checkDetails.ifsc} onChange={handleChange} placeholder="Name" />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="form-group">
              <label for="exampleInputEmail1">MICR Code</label>
              <input type="text" className="form-control" name="micr" value={checkDetails.micr} onChange={handleChange} placeholder="Name" />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="form-group">
              <label for="exampleInputEmail1">Amount Type</label>
              <input type="text" className="form-control" name="accountType" value={checkDetails.accountType} onChange={handleChange} placeholder="Name" />
            </div>
          </Grid>
          
        </Grid>

        
      </div>  
        :""}
        <div className={s["form-login-btn"]} style={{width:"50%"}} onClick={() => handleDealClose()}>
          <Custombutton onClick={()=>handleDealClose()}>Deal Close </Custombutton>
        </div>
        </Box>
      </Modal>
      <OtpModal 
      otpModalShow={otpModalShow} 
      setotpModalShow={setotpModalShow} 
      mobileNo={state?.mobileno} 
      type={"paidlisting"}
      paymentDetails={paymentDetails}
      paymentType={paymentType}
      state={checkDetails}
      />
    </div>
  );
}
